import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Container, Row, Col, Alert } from 'reactstrap';
import DownloadReportsForm from './../../components/Account/DownloadReportsForm';
import { changePassword } from './../../actions/UserActions';
import { getReport } from './../../actions/AccountActions';
import { withRouter } from 'react-router-dom';

class DownloadReportsContainer extends Component {
  constructor(props) {
    super(props);

    this.handleOnSave = this.handleOnSave.bind(this);
  }

  handleOnSave = formValues => {
    const values = JSON.parse(JSON.stringify(formValues));
    values.token = this.props.user.token;
    this.props.getReport(values);
  }

  render() {
    const { loading, error, success, user } = this.props;
    //const loading = false, error = false, success = true;

    const messageSucces = () => {
      return (
        <div className="nosotros-section">
          <h4 className="first-title text-center mt-4 mb-4">Contraseña modificada</h4>
          <p className="mb-4 text-size-medium">
            Tu contraseña ha sido cambiada con éxito
          </p>
          <button
            type="button"
            className="btn btn-primary mb-3"
            onClick={() => this.props.history.push('/')}
          >
            Regresar al Inicio
          </button>
        </div>
      );
    };

    return (
      <Col sm="12" md={{ size: 8, offset: 2 }} className={'return-seccion'}>
        {success ? messageSucces() :
          <section className="border mt-5 mb-5 p-5 back-white border-container">
            <section className="nosotros-section text-center">
              <h1 className="second-title mt-4 mb-4"><b>Descargar reportes</b></h1>
              <p className="mb-5 text-size-medium">Completa los siguientes campos</p>
              <DownloadReportsForm
                onSubmit={this.handleOnSave}
                loadingForm={loading}
              />
              {error && <Alert className="mt-3 mb-0" color="danger"> {error} </Alert>}
            </section>
          </section>
        }
      </Col>

    );
  }
}

function mapStateToProps(state) {
  const { loading, error, success } = state.account.reports;
  const { user } = state.authentication;
  return {
    loading,
    error,
    success,
    user
  };
}

const mapDispatchToProps = dispatch => {
  return {
    getReport(values) {
      dispatch(getReport(values));
    }
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(DownloadReportsContainer));

// Dependencies
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Col, Row, Container } from 'reactstrap';
import { Redirect } from 'react-router-dom';

//Components
import GameForm from './../../components/Game/GameForm';
import ModalComponent from './../../components/Modal/ModalComponent';
import ProductAdded from './../../components/Cart/ProductAdded';
import LotteryNotSelected from './../../components/Game/LotteryNotSelected';

//Actions
import { addProductCart } from './../../actions/CartActions';
import { toggleModalGeneral } from './../../actions/ModalActions';

class GameContainer extends Component {
  constructor(props) {
    super(props);

    this.state = {
      valueReload: 12000,
      redirect: false
    };

    this.handleAddGame = this.handleAddGame.bind(this);
    this.onSelectLottery = this.onSelectLottery.bind(this);
  }

  componentDidMount () {
    if (!(this.props.lottery &&
        this.props.lottery.ideLoteria === parseInt(this.props.match.params.idLotterie) &&
        this.props.lottery.sorteo === this.props.match.params.idSorteo)) {
      this.props.toggleModalGeneral();      
    };
    window.scrollTo(0, 0); 
  }

  onSelectLottery () {
    this.props.toggleModalGeneral();
    this.props.history.replace('/loterias');
  }

  handleAddGame = (redirect, values) => {
    const keys = Object.keys(values);
    values.numero = '';
    values.fracciones = 0;
    values.total = parseInt(values.cartVal);
    values.abreviatura = this.props.lottery.abreviatura;
    values.lottery = this.props.lottery;
    keys.forEach((key, index) => {
      if (key.includes('numero')) {
        values.numero += values[key];
        delete values[key];
      } else if (key.includes('fraccion') && values[key]) {
        values.fracciones += 1;
        delete values[key];
      }
    });

    this.setState({ valueReload: values.total });

    if (redirect) {
      this.setState({redirect: true});
    }
    this.props.addProductCart(values, 'lotteries', redirect);
  }

  render() {
    const {
      match: {
        params: {
          idLotterie = 0,
          idSorteo = 0
        }
      },
      loading, error, succes, lottery
    } = this.props;

    const initialValues = {
      'cartVal': 0
    };

    const { redirect } = this.state;

    return (
      <Col xs={12} className="gameContainer">
        <Row>
          {!loading && succes && (!redirect ?
            <ModalComponent className="modal-dialog-centered modal-md">
              <ProductAdded typeReload='Juego Lotería' valueReload={this.state.valueReload}/>
            </ModalComponent> :
            <Redirect to='/loterias' />)
          }
          {
            (lottery &&
              lottery.ideLoteria == parseInt(idLotterie) &&
              lottery.sorteo == idSorteo) ?
              <Container>
                <GameForm
                  onSubmit={this.handleAddGame}
                  loadingForm={loading}
                  errorForm={error}
                  initialValues={initialValues}
                  lottery={this.props.lottery}
                  idLotterie={idLotterie}
                  sorteoLotterie={idSorteo}
                />
              </Container> :
              <ModalComponent className="modal-dialog-centered modal-lg" customToggleModal={this.onSelectLottery}>
                <LotteryNotSelected toggleModal={this.onSelectLottery}/>
              </ModalComponent>
          }
        </Row>
      </Col>
    );
  }
}

const mapStateToProps = (state) =>{
  const { loading, error, succes } = state.cart;
  const { lottery } = state.game;
  return {
    loading,
    error,
    succes,
    lottery
  };
};

const mapDispatchToProps = dispatch => {
  return {
    addProductCart(values, typeProduct, redirect) {
      dispatch(addProductCart(values, typeProduct, 'GameForm', redirect));
    },
    toggleModalGeneral() {
      dispatch(toggleModalGeneral());
    }
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(GameContainer);

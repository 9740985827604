// Dependencies
import React, { Component } from 'react';
import { connect } from 'react-redux';
import Slider from 'react-slick';

//Images
import fondoBannerLoading from './../../assets/images/fondo-loading-banner-home.png';

//Actions
import { loadSliderHome } from './../../actions/SliderActions';

class SliderHomeContainer extends Component {
  constructor(props) {
    super(props);
  }

  componentDidMount() {
    this.props.loadSliderHome();
  }

  render () {
    const { loading, error, sliders } = this.props;
    const settings = {
      dots: true,
      arrows: true,
      infinite: true,
      speed: 500,
      autoplaySpeed: 6000,
      slidesToShow: 1,
      slidesToScroll: 1,
      fade: true,
      pauseOnHover: false,
      autoplay: true,
      initialSlide: 0
    };

    return (
      <Slider {...settings} className="home-main-slider">
        {loading && <div><img src={fondoBannerLoading}/><div className="absolute-container-center"><i className="fa fa-spinner fa-spin d-inline-block color-main"/></div></div>}
        {sliders && sliders.map((item, i) => {
          return (
            <div key={i}>
              {item.link ?
                <a href={item.link} target={item.blank ? '_blank' : '_self'}>
                  <img src={item.image} alt={item.title} className="img-full w-100 single-img-slider"/>
                </a> :
                <img src={item.image} alt={item.title} className="img-full w-100 single-img-slider"/>
              }
            </div>
          );
        })}
        {error && <div><img src={fondoBannerLoading}/></div>}
      </Slider>
    );
  };
}

const mapStateToProps = (state) =>{
  const { loading, error, sliders } = state.slider.sliderHome;
  return {
    loading,
    error,
    sliders
  };
};

const mapDispatchToProps = dispatch => {
  return {
    loadSliderHome() {
      dispatch(loadSliderHome());
    }
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(SliderHomeContainer);

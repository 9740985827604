import React, { useState, useEffect } from 'react';
import { Alert } from 'reactstrap';
import { OrderService, getPaymentState } from './../../services/OrderService';
import moment from 'moment';

import { formatMoney } from './../../helpers/formatMoney';

const TableHistory = ({ shoppingList }) => {
  const [data, setData] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(5);

  const PriceCell = ({ value }) => {
    if (value === undefined || value === null || isNaN(value)) {
      return <span>$ 0</span>;
    }
    return <span>$ {formatMoney(value, 0, 3, '.', ',')}</span>;
  };

  useEffect(() => {
    if (shoppingList) {
      const processedList = shoppingList.map(element => ({
        ...element,
        state: getPaymentState(element.state),
        typeOfPurchase: element.lotteries.length > 0
          ? (element.recharges.length > 0 ? 'Loterías y Recargas' : 'Loterías')
          : 'Recargas',
        orderMontoTotal: element.order?.monto?.total || 0,
      }));
      setData(processedList);
    }
  }, [shoppingList]);

  const handleClickPDF = async (order) => {
    if (!order || !order.order) {
      console.error('Invalid order data:', order);
      return;
    }

    const infOrder = await order.order;
    console.log('infOrder ------------->>>>  ', infOrder);

    const orderData = [
      {
        label: 'Resultado de la transacción',
        value: infOrder.estado
      },
      {
        label: 'Nombre del comprador',
        value: `${infOrder.comprador?.name || ''} ${infOrder.comprador?.surname || ''}`
      },
      {
        label: 'Email',
        value: infOrder.comprador?.email || ''
      },
      {
        label: 'Documento',
        value: infOrder.comprador?.document || ''
      },
      {
        label: 'Teléfono',
        value: infOrder.comprador?.mobile || ''
      },
      {
        label: 'Fecha de la transacción',
        value: moment(infOrder.fecha).format('LLL')
      },
      {
        label: 'Moneda',
        value: infOrder.monto?.currency || ''
      },
      {
        label: 'Total pagado',
        value: `$ ${formatMoney(infOrder.monto?.total || 0, 0, 3, '.', ',')}`
      },
      {
        label: 'Banco',
        value: infOrder.banco || ''
      },
      {
        label: 'Referencia',
        value: infOrder.referencia
      },
      {
        label: 'Cus/Autorización',
        value: infOrder.cus || ''
      },
      {
        label: 'Descripción',
        value: infOrder.descripcion || ''
      },
      {
        label: 'Dirección IP',
        value: infOrder.ip || ''
      }
    ];

    if (infOrder.descuento) {
      orderData.splice(7, 0, {
        label: 'Valor total',
        value: `$ ${formatMoney(infOrder.totalSinDescuento, 0, 3, '.', ',')}`
      });
      orderData.splice(8, 0, {
        label: 'Descuento',
        value: `$ ${formatMoney(infOrder.descuento, 0, 3, '.', ',')}`
      });
    } else if (infOrder.monto?.descuento) {
      orderData.splice(7, 0, {
        label: 'Valor total',
        value: `$ ${formatMoney(infOrder.monto.totalSinDescuento, 0, 3, '.', ',')}`
      });
      orderData.splice(8, 0, {
        label: 'Descuento',
        value: `$ ${formatMoney(infOrder.monto.descuento, 0, 3, '.', ',')}`
      });
    }
    OrderService.getPDFOrder(orderData);
  };

  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  const handleItemsPerPageChange = (event) => {
    setItemsPerPage(event.target.value);
    setCurrentPage(1);
  };

  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentItems = data.slice(indexOfFirstItem, indexOfLastItem);

  const getStateClass = (state) => {
    switch (state) {
      case 'PENDIENTE':
        return 'estado-pendiente';
      case 'APROBADO':
        return 'estado-aprobado';
      case 'RECHAZADO':
        return 'estado-rechazado';
      default:
        return '';
    }
  };

  return (
    <section className="table-custom-data table-shopping-history">
      {data.length === 0 && (
        <Alert className="my-2 content-center" color="warning">
          No se han encontrado resultados
        </Alert>
      )}
      {data.length > 0 && (
        <div className='table-full-width'>
          <div className='lotti-table'>
            <div className='lotti-row header'>
              <div className='cell'>FECHA</div>
              <div className='cell'>TIPO DE COMPRA</div>
              <div className='cell'>VALOR</div>
              <div className='cell'>CÓDIGO</div>
              <div className='cell'>ESTADO</div>
              <div className='cell'>COMPROBANTE</div>
            </div>

            {currentItems.map((row) => (
              <div className='lotti-row' key={row.id}>
                <div className='cell' data-title="FECHA">{moment(row.updatedAt).format('DD/MMM/YYYY').replace('.', '')}</div>
                <div className='cell' data-title="TIPO DE COMPRA">{row.typeOfPurchase}</div>
                <div className='cell' data-title="VALOR"><PriceCell value={row.orderMontoTotal} /></div>
                <div className='cell' data-title="CÓDIGO">{row.reference}</div>
                <div className='cell ' data-title="ESTADO">
                  <p className={` ${getStateClass(row.state)}`}>
                    {row.state}
                  </p>
                </div>
                <div className='cell' data-title="COMPROBANTE">
                  <button
                    type="button"
                    className="btn-download"
                    onClick={() => handleClickPDF(row)}
                  >
                    <i className="flaticon-file" />
                  </button>
                </div>
              </div>
            ))}
          </div>

          <div className="pagination-container row">
            <div className='col-12'>
              <button
                className="pagination-btn btn-primary"
                onClick={() => handlePageChange(currentPage - 1)}
                disabled={currentPage === 1}
              >
                Anterior
              </button>
              <span>Página {currentPage}</span>
              <button
                className="pagination-btn btn-primary"
                onClick={() => handlePageChange(currentPage + 1)}
                disabled={currentPage * itemsPerPage >= data.length}
              >
                Siguiente
              </button>
            </div>
            <div className='col-12'>
              <select
                onChange={handleItemsPerPageChange}
                value={itemsPerPage}
                className="pagination-select"
              >
                <option value={5}>5</option>
                <option value={10}>10</option>
                <option value={15}>15</option>
              </select>
            </div>
          </div>
        </div>
      )}
    </section>
  );
};

export default TableHistory;

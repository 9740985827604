// Dependencies
import React, { Component, useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

// Components
import Header from './components/Global/Header/Header';
import Content from './components/Global/Content/Content';
import Footer from './components/Global/Footer/Footer';
import ModalLogin from './containers/Login/ModalLogin';
import Alert from './containers/Alert/Alert';
import ModalConfirmUpdate from './containers/Login/ModalConfirmUpdate';
import ModalFavorite from './containers/Login/ModalFavorite';

// Data
import items from './shared/menu';

//CSS
import 'bootstrap/dist/css/bootstrap.min.css';
import 'font-awesome/css/font-awesome.min.css';
import './assets/styles/global.scss';


class App extends Component {

  static propTypes = {
    children: PropTypes.object.isRequired
  };

  render() {
    const { children } = this.props;

    return (
      <div className="App">
        <ModalLogin />
        <ModalConfirmUpdate />
        <ModalFavorite />
        <Alert />
        <Header
          title="LotiColombia"
          items={items}
        />
        <Content body={children} />
        <Footer title="LotiColombia" />
      </div>
    );
  }
}
export default connect()(App);

import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Row, Col, Alert } from 'reactstrap';
import { withRouter } from 'react-router-dom';

//Components
import CircularProgress from './../../components/Global/CircularProgress/CircularProgress';
import { loadResultsHome } from './../../actions/ResultsActions';
import { loadLotteriesHome } from './../../actions/LotteriesActions';
import { setLotteryToPlay } from './../../actions/GameActions';
import SingleResult from './../../components/Results/SingleResult';
import {
  pushEskEvent,
  pushFbqEvent,
  pushGtagEvent
} from '../../utils/metricManagementUtil';
//Constants

class ResultsHomeContainer extends Component {
  constructor(props) {

    super(props);
    this.state = {
      dateResults: null
    };

    this.handleOnClickLotterie = this.handleOnClickLotterie.bind(this);
  }

  componentDidMount() {
    this.props.loadResultsHome({
      fecha: null,
      limite: 4
    });
    this.props.loadLotteriesHome({
      fecha: '11-04-2018',
      evento: null
    });
  }


  handleOnClickLotterie(idLotterie, numeroFavorito) {
    pushGtagEvent('play_now_favourite', { lotteryId: idLotterie, favourite: numeroFavorito });
    const selectedLoti = this.props.lotteries.filter(lotterie => lotterie.ideLoteria === idLotterie)[0];
    localStorage.setItem('numero', numeroFavorito);
    if (selectedLoti) {
      this.props.setLotteryToPlay(selectedLoti);
      this.props.history.push(`/juego/${idLotterie}/${selectedLoti.sorteo}`);
    }
  }


  render() {
    const { loading, error, results } = this.props;
    const baseURL = process.env.REACT_APP_BASEURL;
    return (

      <Row className="p-5 m-sm-0">
        {loading && <Col xs={12}><CircularProgress /></Col>}
        {results && !loading && (results.length > 0 ? results.map((result, i) => {
          //result.serie = parseInt(result.serie);
          const planes = result.ideClaseLoteria == 1 ? true : false;
          return (
            <Col key={i} xs={12} sm={6} md={6} lg={6} className="mb-5 align-content-center">
              <SingleResult key={i} data={result} icon={`${baseURL}/assets/images/loterias/${result.sigaLoteria || result.siglaLoteria}.png`} complete={false}
                tradicional={!planes} onLotterieClick={() => this.handleOnClickLotterie(result.ideLoteria, result.numeroFavorito)}
              />
            </Col>
          );
        }) : <Alert className="my-2 content-center" color="light"> No se encontraron resultados para la fecha. </Alert>)}
        {error && <Alert className="my-2 content-center" color="danger"> {error} </Alert>}

      </Row>

    );
  }
}

const mapStateToProps = (state) => {
  const { loading, error, results } = state.results.resultsHome;
  const { lotteries } = state.lotteries.lotteriesHome;
  return {
    loading,
    error,
    results,
    lotteries
  };
};

const mapDispatchToProps = dispatch => {
  return {
    loadResultsHome(values) {
      dispatch(loadResultsHome(values));
    },
    loadLotteriesHome(values) {
      dispatch(loadLotteriesHome(values));
    },
    setLotteryToPlay(lottery) {
      dispatch(setLotteryToPlay(lottery));
    }
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(ResultsHomeContainer));

/* eslint-disable max-len */
// Dependencies
import React, { Component, useState } from 'react';
import PropTypes from 'prop-types';
import { Container, Row, Col } from 'reactstrap';
import LogoReditos from './../../../assets/images/Reditos.svg';
import LogoPlacetopay from './../../../assets/images/Placetopay.svg';
import LogoPayValidar from './../../../assets/images/icon-payvalidar.png';
import LogoFacebook from './../../../assets/images/icon-fb.svg';
import LogoInstagram from './../../../assets/images/icon-in.svg';
import LogoYouTube from './../../../assets/images/icon-yt.svg';
import logoPSE from './../../../assets/images/PSE.svg';
import logoVISA from './../../../assets/images/VISA.svg';
import logoMASTER from './../../../assets/images/master.svg';

const Footer = ({ title }) => {

  const LinkGruposEnv = process.env.REACT_APP_LINK_GRUPOS_REDITOS;
  const LinkPlaycetopayEnv = process.env.REACT_APP_LINK_PLAYCETOPAY;
  const LinkCreditoRisEnv = process.env.REACT_APP_LINK_CREDITO_RIS;
  const LinkFacebookEnv = process.env.REACT_APP_FACEBOOK_LOTI;
  const LinkInstagramEnv = process.env.REACT_APP_INSTAGRAM_LOTI;
  const LinkYouTubeEnv = process.env.REACT_APP_YOUTUBE_LOTI;

  return (
    <footer className="custom-footer">
      <div className='py-5 px-2 container-footer'>
        <Row className="align-items-center">
          <Col xs={{ size: 11, offset: 1 }} md={{ size: 10, offset: 2 }} lg={{ size: 10, offset: 2 }} className="align-margin-center">
            <Row>
              <Col xs={12} sm={6} md={6} lg={2} className="center-flex  ">
                <div className='single-seccion-footer'>
                  <div className='pr-4'>
                    <a href="/nosotros/nuestra-empresa" className="text-info-light">
                      <h1 className='navbar-footer' style={{ height: '35px' }}>Nosotros</h1>
                      <br />
                    </a>
                    <div className='single-hr-footer' />
                    <a href="/nosotros/nuestra-empresa" className="footer-navar-seccion">Nuestra empresa</a><br />
                    <a href="/nosotros/nuestra-red" className="footer-navar-seccion">Nuestra red</a><br />
                    <a href="/productos" className="footer-navar-seccion">Productos</a><br />
                  </div>
                </div>
              </Col>
              <Col xs={12} sm={6} md={6} lg={2} className="center-flex">
                <div className='single-seccion-footer'>
                  <div className='pr-4'>
                    <a href="/puntos-gana" className="text-info-light">
                      <h1 className='navbar-footer' style={{ height: '35px' }}>Puntos Gana</h1><br />
                    </a>
                    <div className='single-hr-footer' />
                    <a href="/puntos-gana" className="footer-navar-seccion">Política de privacidad</a><br />
                    <a href="/puntos-gana" className="footer-navar-seccion">Aliados</a><br />
                    <a href="/puntos-gana" className="footer-navar-seccion">Conoce el programa</a><br />
                  </div>
                </div>
              </Col>
              <Col xs={12} sm={6} md={6} lg={2} className="center-flex">
                <div className='single-seccion-footer'>
                  <div className='pr-4'>
                    <a href="/contacto/pqrs" className="text-info-light">
                      <h1 className='navbar-footer' style={{ height: '35px' }}>Servicio al cliente</h1><br />
                    </a>
                    <div className='single-hr-footer' />
                    <a href="/contacto/pqrs" className="footer-navar-seccion">PQRS</a><br />
                    <a href="/contacto/faq" className="footer-navar-seccion">Preguntas frecuentes</a><br />
                    <a href={LinkCreditoRisEnv} target='_blank' className="footer-navar-seccion">Solicita tu crédito</a><br />
                  </div>
                </div>
              </Col>
              <Col xs={12} sm={6} md={6} lg={2} className="center-flex single-logo-gooter ">
                <div className='single-seccion-footer'>
                  <div className='pr-4'>
                    <a className="text-info-light">
                      <h1 className='navbar-footer' style={{ height: '35px' }}>Síguenos</h1><br />
                    </a>
                    <div className='single-hr-footer' />
                    <div href="/nosotros/nuestra-empresa" className="text-center logo-footer-lotti">
                      <div className="align-items-center social-footer-icons">
                        <a href={LinkFacebookEnv} target='_blank'><img src={LogoFacebook} /></a>
                        <a href={LinkInstagramEnv} target='_blank'><img src={LogoInstagram} /></a>
                        <a href={LinkYouTubeEnv} target='_blank'><img src={LogoYouTube} /></a>
                      </div>
                    </div>
                    <div className='mt-3'>
                      <a href={LinkGruposEnv} target="_blank" className="d-inline-block  footer-containers">
                        <img src={LogoReditos} />
                      </a>
                    </div>
                    <div className='mt-3'>
                      <a href={LinkPlaycetopayEnv} target="_blank" className="d-inline-block  footer-containers">
                        <img src={LogoPlacetopay} />
                      </a>
                    </div>
                    <div className='mt-3'>
                      <a target="_blank" className="d-inline-block  footer-containers">
                        <img src={LogoPayValidar} />
                      </a>
                    </div>
                  </div>
                </div>
              </Col>
              <Col xs={12} sm={6} md={6} lg={2} className="center-flex single-logo-gooter ">
                <div className='single-seccion-footer-final'>
                  <a className="text-info-light">
                    <h1 className='navbar-footer' style={{ height: '35px' }}>Medios de pago</h1><br />
                  </a>
                  <div className='single-hr-footer' />
                  <div className='mt-3'>
                    <a target="_blank" className="d-inline-block  footer-containers">
                      <img src={logoPSE} height={25} width={70} />
                    </a>
                  </div>
                  <div className='mt-3'>
                    <a target="_blank" className="d-inline-block  footer-containers">
                      <img src={logoVISA} height={25} width={70} />
                    </a>
                  </div>
                  <div className='mt-3'>
                    <a target="_blank" className="d-inline-block  footer-containers">
                      <img src={logoMASTER} height={25} width={70} />
                    </a>
                  </div>
                </div>
              </Col>
            </Row>
          </Col>
        </Row>
      </div>
      <div className='custom-footer-seccion text-center'>
        <Row className='align-items-center'>
          {/* <Col xs={{ size: 10, offset: 1 }} md={{ size: 10, offset: 1 }} className="align-margin-center p-4">
            <Row className="align-items-center">
              <Col xs={6} md="3" className="center-flex select-footer mb10">
                <a href={LinkGruposEnv} target="_blank" className="d-inline-block  footer-containers">
                  <img
                    className="img-fluid"
                    src={LogoReditos} title={'Grupo Réditos'} alt={'Grupo Réditos'}
                  />
                </a>
              </Col>
              <Col xs={6} md="3" className="center-flex select-footer mb10">
                <a href={LinkOkiEnv} target="_blank" className="d-inline-block  footer-containers">
                  <img
                    className="img-fluid footer-imaginamosLogo"
                    src={LogoOKI} title={'Imaginamos'} alt={'Imaginamos'}
                  />
                </a>
              </Col>
              <Col xs={6} md="3" className="center-flex select-footer">
                <a href={LinkPlaycetopayEnv} target="_blank" rel="noopener noreferrer">
                  <img src={LogoPlacetopay} alt={'placetopay'} />
                </a>
              </Col>
              <Col xs={6} md="3" className="center-flex select-footer">
                <a href={LinkGanaEnv} target="_blank" className="d-inline-block  footer-containers">
                  <img
                    className="img-fluid"
                    src={LogoGana} title={'Gana Web'} alt={'Gana Web'}
                  />
                </a>
              </Col>
            </Row>
          </Col> */}
          {/* <Col xs={12} md="12" className="text-info-light font-montserrat-light text-size-13">
              <p className="text-right">
                Copyright © {title + (new Date().getFullYear())}.
                Prohibida su reproducción parcial, así como su traducción a cualquier idioma sin
                autorización de LotiColombia.
              </p>
            </Col> */}
          <Col xs={{ size: 12 }} className="text-info-light font-montserrat-light text-size-small single-copy">
            <p className="text-center" style={{ margin: '0' }}>
              {/* <a href="https://www.loticolombia.com/politicas-privacidad" className="link-decoration" target="_blank" rel="noopener noreferrer">Política de tratamiento de datos personales</a> para el uso del servicio de Compra de Lotería */}
              <a href={'/politicas-privacidad'} className="link-decoration" rel="noopener noreferrer">
                Política de tratamiento de datos personales
              </a>
              &nbsp;
              para el uso del servicio de Compra de Lotería &nbsp;
              <a href="/terminos-y-condiciones" className="link-decoration" rel="noopener noreferrer">Términos y condiciones</a>
            </p>
            <p className="text-center" style={{ margin: '0' }}>
              Copyright © {title + (new Date().getFullYear())}.
              Prohibida su reproducción parcial, así como su traducción a cualquier idioma sin
              autorización de LotiColombia.
            </p>
          </Col>
        </Row>
      </div>
    </footer>
  );
};

Footer.propTypes = {
  title: PropTypes.string
};

export default Footer;

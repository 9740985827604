import React from 'react';
import { reduxForm, Field } from 'redux-form';
import { Link } from 'react-router-dom';

//Components
import renderField from './../renderFields/renderField';

//Utils
import Validators from './../renderFields/validatorsFields';

const validate = values => {
  const errors = {};

  return errors;
};

const DeleteAccountForm = ({
  handleSubmit,
  onDelete,
  pristine,
  reset,
  submitting,
  loadingForm
}) => {
  return (
    <form onSubmit={handleSubmit}>

      <Field
        name="motivoCancelacion"
        id="delete-account"
        type="text"
        icon="flaticon-profile"
        label="Motivo"
        component={renderField}
        validate={[Validators.required, Validators.maxLength150]}
      />

      <div className="mt-4 result-position">
        <button
          type="submit"
          className="btn btn-primary"
          disabled={loadingForm}
        >
          Eliminar mi cuenta
          {loadingForm && <i className="fa fa-spinner fa-pulse ml-2 d-inline-block" />}
        </button>
      </div>
      <div className='mt-4 result-position'>
        <Link title="datos" to="/mi-cuenta/datos">
          <span>Volver</span>
        </Link>
      </div>
    </form>
  );
};

export default reduxForm({ form: 'DeleteAccountForm', validate })(DeleteAccountForm);

// Dependencies
import React, { Component } from 'react';
import { Col, Row, Container, TabContent, TabPane, Nav, NavItem, NavLink } from 'reactstrap';
import { connect } from 'react-redux';

//Components
import NuestraEmpresa from './../../components/About/NuestraEmpresa';
import NuestraRed from './../../components/About/NuestraRed';
import Breadcrumbs from './../../components/Global/Breadcrumbs/Breadcrumbs';

//Actions
import { getPageInfo } from './../../reducers/generalReducer';
import { Tab, TabList, Tabs } from 'react-tabs';

class AboutContainer extends Component {
  constructor(props) {
    super(props);

    const currentPath = props.location.pathname;

    const pathSegments = currentPath.split('/');
    const lastSegment = pathSegments[pathSegments.length - 1];
    this.state = {
      activeTab: lastSegment
    };

    this.toggle = this.toggle.bind(this);
  }

  toggle(tab) {
    if (this.state.activeTab !== tab) {
      this.setState({
        activeTab: tab
      });
      this.props.history.push(`/nosotros/${tab}`);
    }
  }
  render() {
    const { infoEmpresa, infoRed } = this.props;
    let defaultIndex;
    switch (this.state.activeTab) {
      case 'nuestra-empresa':
        defaultIndex = 0;
        break;
      case 'nuestra-red':
        defaultIndex = 1;
        break;
      default:
        defaultIndex = 0;
        break;
    }
    return (
      <Col xs={12} className="homeContainer">
        <Row>
          <Container className="bg-primary">
            <Breadcrumbs />
            <section className="mb-4 px-4 pb-4">
              <div className="tabs-page">
                <Tabs tabs fill defaultIndex={defaultIndex}>
                  <TabList className="react-tabs__tab-list tab-container">
                    <Tab
                      className="button-rounded button-rounded-puntos px-20 py5"
                      onClick={() => this.toggle('nuestra-empresa')}
                    >
                      Nuestra Empresa
                    </Tab>
                    <Tab
                      className="button-rounded button-rounded-puntos px-20 py5"
                      onClick={() => this.toggle('nuestra-red')}
                    >
                      Nuestra Red
                    </Tab>
                  </TabList>
                </Tabs>
                <TabContent activeTab={this.state.activeTab}>
                  <TabPane tabId="nuestra-empresa" className="text-center">
                    {this.state.activeTab === 'nuestra-empresa' && infoEmpresa && <NuestraEmpresa info={infoEmpresa} />}
                  </TabPane>
                  <TabPane tabId="nuestra-red" className="text-center">
                    {this.state.activeTab === 'nuestra-red' && infoRed && <NuestraRed info={infoRed} />}
                  </TabPane>
                </TabContent>
              </div>
            </section>
          </Container>
        </Row>
      </Col>
    );
  }
}

const mapStateToProps = (state) => {
  const { loading, error } = state.general.pages;
  return {
    loading,
    error,
    infoEmpresa: getPageInfo(state, 'nuestra-empresa'),
    infoRed: getPageInfo(state, 'nuestra-red')
  };
};

export default connect(mapStateToProps)(AboutContainer);

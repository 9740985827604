import 'whatwg-fetch';
import {decryptUserData,encryptUserData} from './security/informationCodingSecurity';
import { getAuthApp } from './security/authApp';
import { decrypt } from './security/encryptorHandlerSecurity';

const serverURL = process.env.REACT_APP_SERVERURL;

const handleResponse = (response) => {
  if (!response.ok) {
    return Promise.reject(response.statusText);
  }
  return response.json();
};

class RecargaOkiService {

  static loadListRecargaOki(values) {
    console.log('recarga-oki-values:', values);
    let obDecryptUserData = {
      usuario: values.usuario
    };

    obDecryptUserData = decryptUserData(obDecryptUserData);
    values.usuario = obDecryptUserData.usuario;
    const obEncryptValues = encryptUserData(values);
    const url = `${serverURL}/api/Users/etn/recarga-oki`;
    console.log('recarga-oki:', obEncryptValues);
    const data = JSON.stringify({body: {...obEncryptValues}}, null, 2);
    console.log('recarga-oki:', obEncryptValues);
    console.log('data:', data);

    return fetch(url, {
      method: 'POST',
      body: data,
      headers: new Headers({
        'content-type': 'application/json',
        'Authorization': getAuthApp()
      })
    })
      .then(handleResponse)
      .then(response => response)
      .catch(error => {
        return Promise.reject(error);
      });
  }

  static enviarSMS(data) {
    console.log('data', data);
    const url = `${serverURL}/api/Users/etn/enviarSMS`;

    return fetch(url, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': getAuthApp()
      },
      body: JSON.stringify({data: {...data}})
    })
      .then(handleResponse)
      .then(response => response)
      .catch(error => {
        return Promise.reject(error);
      });
  }

  static enviarCorreo(data) {
    console.log('data', data);
    const url = `${serverURL}/api/Users/etn/enviarCorreo`;

    return fetch(url, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': getAuthApp()
      },
      body: JSON.stringify({data: {...data}})
    })
      .then(handleResponse)
      .then(response => response)
      .catch(error => {
        return Promise.reject(error);
      });
  }

  static BuscarS3(data) {
    console.log('data', data);
    const url = `${serverURL}/api/Users/etn/BuscarS3`;

    return fetch(url, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': getAuthApp()
      },
      body: JSON.stringify({data: {...data}})
    })
      .then(handleResponse)
      .then(response => {
        const signedUrl = response.data;
        console.log('signedUrl:', signedUrl);

        return signedUrl;
      })
      .catch(error => {
        return Promise.reject(error);
      });
  }

  static enviarCorreoReporte(data) {
    console.log('data', data);

    return this.BuscarS3({numTransaccionUsuario: data.numTransaccionUsuario})
      .then(signedUrl => {
        console.log('signedUrl:', signedUrl);
        console.log('data', data.numTransaccionUsuario);
        const url = `${serverURL}/api/Users/etn/enviarCorreoReporte`;

        return fetch(url, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            'Authorization': getAuthApp()
          },
          body: JSON.stringify({ data: { ...data, signedUrl } })
        });
      })
      .then(handleResponse)
      .catch(error => {
        return Promise.reject(error);
      });
  }
}

export default RecargaOkiService;

import React, { Component } from 'react';
import { FormGroup, FormText } from 'reactstrap';
import { connect } from 'react-redux';

class renderRadioButtonGame extends Component {
  constructor (props) {
    super(props);

    this.state = {
      valueInput: ''
    };

    this.handleChangeInput = this.handleChangeInput.bind(this);
  }

  handleChangeInput(event) {
    const valueInp = (event.target.value.replace(/[^\d]/g, '')).substring(0, 3);
    this.setState({valueInput: valueInp});
    if ( valueInp.length === 3 ) {
      const serie = this.props.radioList.filter(radio => radio.value === valueInp);
      if (!serie.length) {
        this.props.input.value = valueInp;
        this.props.changeCustomSerie(valueInp);
      }
    }
  }

  render () {
    const {input, label, id, radioList, indexRadio, singleSerie, formGroupClass, changeCustomSerie, meta: {touched, error, warning}, ...rest} = this.props;
    return (
      <FormGroup className={formGroupClass}>
        {radioList && radioList.length && radioList.map((radio, index) => {
          const idRadio = `radioSerie${index}`;
          const value = radio.value ? radio.value : (singleSerie.success ? this.state.valueInput : '');
          return (
            <div className="d-inline-block mb-4 mx-2" key={index}>
              {radio.edited && <span className="d-inline-block text-white vertical-middle"></span>}
              <div className={`custom-radio-button custom-radio-button-inline d-inline-block ${radio.edited && 'radio-input'}`}>
                <input
                  type="radio"
                  id={idRadio}
                  {...input}
                  value={value}
                  disabled={!radio.value && !singleSerie.success}
                  checked={(input.value === value && value.length)}
                />

                <div className='d-flex justify-content-center'>
                  {radio.edited && <input className='d-flex align-items-center justify-content-center' style={{width:'100%'}} type="text" placeholder='Otra' value={this.state.valueInput} onChange={this.handleChangeInput} disabled={singleSerie.loading} />}
                  {!radio.edited && <label className='d-flex align-items-center justify-content-center' htmlFor={idRadio}>{value}</label>}
                </div>
                
              </div>
            </div>
          );
        })}
        {touched &&
        ((error && <FormText className="text-left">{error}</FormText>) ||
        (warning && <FormText className="text-left">{warning}</FormText>))}
      </FormGroup>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    singleSerie: state.game.singleSerie
  };
};


export default connect(mapStateToProps)(renderRadioButtonGame);

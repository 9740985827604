import React, { Component } from 'react';
import { Row, Col, Alert } from 'reactstrap';

//Components
import SubpageBlock from './../../containers/Products/SubpageBlock';

class NuestraEmpresa extends Component {
  constructor(props) {
    super(props);
    this.state = {
      activeIndex: 1,
    };
  }

  toggleAccordion = (index) => {
    this.setState((prevState) => ({
      activeIndex: prevState.activeIndex === index ? null : index,
    }));
  };

  render() {
    const { info } = this.props;
    const title = (info.title) ? info.title.split('||') : ['', ''];
    return (
      <section className="nosotros-section">
        <section className="font-montserrat-light mb-5 text-center" />
        <button
          className={`accordion ${this.state.activeIndex === 1 ? 'active' : ''}`}
          onClick={() => this.toggleAccordion(1)}
        >
          <h4 className="text-left text-bold collapse-title">{info.title} <i className={`fa ${this.state.activeIndex === 1 ? 'fa-chevron-up' : 'fa-chevron-down'}`}></i></h4>
        </button>
        <div
          className="panel"
          style={{
            display: this.state.activeIndex === 1 ? 'block' : 'none',
          }}
        >
          <Row className="align-center p-5">
            <Col lg={7}>
              <article dangerouslySetInnerHTML={{ __html: info.description }} />
            </Col>
            <Col lg={5} className="pr-0">
              <figure class="ratio-2-1">
                <img src={info.image} className="d-block w-100" />
              </figure>
            </Col>
          </Row>
        </div>
        {info.subpages && info.subpages.length > 0 && info.subpages.map((subpage) => (
          <SubpageBlock subpage={subpage} activeIndex={this.state.activeIndex} toggleAccordion={this.toggleAccordion} />
        ))}
      </section>
    );
  }
}

export default NuestraEmpresa;

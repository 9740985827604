import React from 'react';
import { FormGroup, Input, FormFeedback, FormText } from 'reactstrap';

const renderField = ({input, label, type, icon, topLabel, meta: {touched, error, warning}, ...rest}) => {

  const getValidationState = () => {
    return error ? false : warning ? false : true;
  };

  const fb = (type) => {
    if ( type === 'text' || type === 'number' || type === 'email' || type === 'date') {
      return true;
    } else {
      return false;
    }
  };

  return (
    <FormGroup>
      <div className={`position-relative form-file-container ${topLabel && 'file-top-label'}`}>
        <Input valid={getValidationState()}
          {...input}
          {...rest}
          type={type}
          placeholder={label} className="form-file no-shadow"
        />
        {topLabel && <label>{label}</label>}
        <i className={`form-file-icon ${icon}`} />
      </div>
      {touched &&
      ((error && <FormText className="text-left">{error}</FormText>) ||
      (warning && <FormText className="text-left">{warning}</FormText>))}
      {fb(type) && <FormFeedback>Oh noes! that name is already taken</FormFeedback>}
    </FormGroup>
  );
};


export default renderField;

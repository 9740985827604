// Dependencies
import React, { Component } from 'react';
import { Col, Row, Container } from 'reactstrap';
import { Link } from 'react-router-dom';
import TagManager from 'react-gtm-module';
import { connect } from 'react-redux';
import { Link as ScrolLink, animateScroll as scroll } from 'react-scroll';
import moment from 'moment';
//Components
import SliderHomeContainer from './../../containers/Sliders/SliderHomeContainer';
import ResultsHomeContainer from './../../containers/Results/ResultsHomeContainer';
import LotteriesHomeContainer from './../../containers/Lotteries/LotteriesHomeContainer';
import NewsHomeContainer from './../../containers/News/NewsHomeContainer';
import LotterieWinnerForm from './../../components/Lotteries/LotterieWinnerForm';
import ModalComponent from './../../components/Modal/ModalComponent';
import IsWinnerFail from './../../components/Lotteries/IsWinner/IsWinnerFail';
import IsWinnerSucces from './../../components/Lotteries/IsWinner/IsWinnerSucces';
import { isWinner } from './../../actions/LotteriesActions';
import HomePagoPremioContainer from '../../containers/PagoPremio/HomePagoPremioContainer';
import { loadHomePayPrize } from '../../actions/HomePayPrizeActions';

//Images
import LogoReditos from './../../assets/images/Reditos.svg';
import LogoPlacetopay from './../../assets/images/Placetopay.svg';
import logoPSE from './../../assets/images/PSE.svg';
import logoVISA from './../../assets/images/VISA.svg';
import logoMASTER from './../../assets/images/master.svg';
import Arriba from './../../assets/images/Arriba.svg';
import Scroll from './../../assets/images/Scroll.svg';
import consultarlogo from './../../assets/images/consulta.svg';
import iconoeresganador from './../../assets/images/icono-eresganador.svg';
import iconoseccion from './../../assets/images/icon-seccion.svg';

//Constants
import { trackingIdConstant } from './../../constants/TrackingIdConstant';
import { getPageInfo } from './../../reducers/generalReducer';
import Response$ from './../../events/emitter';


const LinkCreditoRisEnv = process.env.REACT_APP_LINK_CREDITO_RIS;
const LinkGruposEnv = process.env.REACT_APP_LINK_GRUPOS_REDITOS;
const LinkPlaycetopayEnv = process.env.REACT_APP_LINK_PLAYCETOPAY;

class Home extends Component {
  constructor(props) {
    super(props);

    this.handleIsWinner = this.handleIsWinner.bind(this);
  }

  handleIsWinner = values => {
    if (moment.isMoment(values.fecha)) {
      values.fecha = values.fecha.format('DD-MM-YYYY');
    }

    this.props.isWinner(values);
  }

  scrollToTop = () => {
    scroll.scrollToTop({
      smooth: true,
      duration: 1000
    });
  };



  render() {
    // ReactGA.initialize('G-2DC8XV08P9',{
    //   debug:true,
    //   titleCase: false
    // });
    // ReactGA.pageview(window.location.pathname + window.location.search);
    const { loading, error, success, info } = this.props;
    const tagManagerArgs = {
      gtmId: trackingIdConstant.GOOGLE_TASK_MANAGER_ID
    };

    TagManager.initialize(tagManagerArgs);

    return (
      <div className="homeContainer">
        <Row>
          {/*---- Main Slider ----*/}
          <SliderHomeContainer />
          {/*---- End Main Slider ----*/}
          <Container className="banner_credito_ris_container">
            <Col xs={{ size: 10, offset: 1 }} md={{ size: 10, offset: 1 }} className="text-center align-margin-center">
              <Row className="align-items-center">
                <Col className="center-flex">
                  <ScrolLink to="lotteries" smooth={true} duration={500} className="class-text">
                    <h1 className='navbar-seccion'>Loterías</h1>
                    <img src={iconoseccion} />
                  </ScrolLink>
                </Col>
                <Col className="center-flex">
                  <ScrolLink to="results" smooth={true} duration={500} className="class-text">
                    <h1 className='navbar-seccion'>Resultados</h1>
                    <img src={iconoseccion} />
                  </ScrolLink>
                </Col>
                <Col className="center-flex">
                  <ScrolLink to="news" smooth={true} duration={500} className="class-text">
                    <h1 className='navbar-seccion'>Novedades</h1>
                    <img src={iconoseccion} />
                  </ScrolLink>
                </Col>
              </Row>
            </Col>
          </Container>

          <Container id="lotteries" className='p-4'>
            <LotteriesHomeContainer />
          </Container>

          <Container id="results" class="banner-eresganador">
            <Row className="mb-5">
              <Col sm={12} className="mb-3">
                <div className="d-flex flex-column justify-content-between ">
                  <h3 className="first-title text-center color-white mt-2">Últimos Resultados</h3>
                  <section className="mt-4 mb-3">
                    <ResultsHomeContainer />
                  </section>
                  <div className='conoce-resultados'>
                    <Link className="button-rounded button-rounded-gradient-resultados px-30 py5 d-inline-block align-middle" to="/resultados">
                      <span>Conoce todos los resultados</span>
                    </Link>
                  </div>
                </div>
              </Col>
            </Row>
          </Container>
          {/*---- Banner Promotional ----*/}
          <Row className="mb-8 single-eresganador">
            <Container>
              <Row id="img-banner">
                <Col xs={12} sm={12} md={7} lg={7} className="text-center align-end posiciones">
                  <img class="img" id="img-consulta" src={consultarlogo} width="500px" />
                </Col>
                <Col xs={12} sm={12} md={5} lg={5} className="text-center" >
                  <h3 className="my-0 d-inline-block text-white">¿Quieres saber si ganaste?</h3>
                  <Col xs={12} sm={12} md={12} lg={12} className="text-center">
                    <br /><br />
                    <LotterieWinnerForm
                      onSubmit={this.handleIsWinner}
                      loadingForm={loading}
                    />
                    {error &&
                      <ModalComponent className="modal-dialog-centered modal-md">
                        <IsWinnerFail />
                      </ModalComponent>
                    }
                    {success &&
                      <ModalComponent className="modal-dialog-centered modal-lg">
                        <IsWinnerSucces />
                      </ModalComponent>
                    }
                  </Col>
                </Col>
              </Row>
            </Container>
          </Row>
          {/*---- End Banner Promotional ----*/}
          <div id="news" className="banner_novedades">
            <Col className="single-container-new">
              <div className="p-4 h-100 d-flex flex-column justify-content-start">
                <h3 className="first-title text-center color-white">Novedades</h3>
                <NewsHomeContainer />
              </div>
            </Col>
          </div>
          <div className="single-eresganador-pago-home">
            <Container>
              <HomePagoPremioContainer />
            </Container>
          </div>
          {/* <div className="payment-methods">
            <Row>
              <Col>
                <Container className="pt60">
                  <div class="line-container">
                    <div class="line" />
                    <div class="circle" />
                  </div>
                </Container>
                <div className='p-4 text-center'>
                  <span className='single-pagos'>Ahora puedes hacer tus pagos con</span>
                </div>
                <Row className='container-pagos'>
                  <Col xl={1} lg={2} md={3} sm={3} xs={3}>
                    <a href={LinkPlaycetopayEnv} target="_blank" rel="noopener noreferrer">
                      <img src={LogoPlacetopay} />
                    </a>
                  </Col>
                  <Col xl={1} lg={2} md={3} sm={3} xs={3}><img src={logoPSE} /></Col>
                  <Col xl={1} lg={2} md={3} sm={3} xs={3}><img src={logoVISA} /></Col>
                  <Col xl={1} lg={2} md={3} sm={3} xs={3}><img src={logoMASTER} /></Col>
                </Row>
              </Col>
            </Row>
          </div> */}
        </Row>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  const { loading, error, success } = state.lotteries.isWinner;
  return {
    loading,
    error,
    success,
    info: getPageInfo(state, 'eres-ganador')
  };
};

const mapDispatchToProps = dispatch => {
  return {
    isWinner(values) {
      dispatch(isWinner(values));
    }
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Home);

import React from 'react';
import { Link } from 'react-router-dom';
import defaultBroken from './../../assets/images/LotiColombia.svg';

const SingleNews = ({ cover, name, summary, id }) => {

  const handleError = (event) => {
    event.target.src = defaultBroken;
  }

  return (
    <div className="mb-3 pt-1 pb-1 single-new">
      <div className='single-noticias'>
        <Link to={`/noticias/${id}`}><img className="img-fluid mb-2 single-news" src={cover} onError={handleError} /></Link>
        <h5 className="second-title text-left"><Link className='single-new-title' to={`/noticias/${id}`}>{name}</Link></h5>
        <p className="font-montserrat-light-news">{summary.slice(0, 100)}{summary.length > 100 ? '...' : ''}</p>
      </div>
      <div className='single-noticias-ver-mas'>
        <Link className='single-text-ver' to={`/noticias/${id}`}>Ver más</Link>
      </div>
    </div>
  );
};

export default SingleNews;

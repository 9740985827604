import React, { Component } from 'react';
import { Row, Col } from 'reactstrap';
import { reduxForm, Field, formValueSelector } from 'redux-form';
import { connect } from 'react-redux';

//Components
import renderRadio from './../../renderFields/renderRadio';
import renderTextArea from './../../renderFields/renderTextArea';
import renderCheckBox from './../../renderFields/renderCheckBox';

//Utils
import Validators from './../../renderFields/validatorsFields';

const optionsRadio = [
  {
    label: 'Excelente',
    value: '1'
  },
  {
    label: 'Muy Bueno',
    value: '2'
  },
  {
    label: 'Bueno',
    value: '3'
  },
  {
    label: 'Regular',
    value: '4'
  },
  {
    label: 'Malo',
    value: '5'
  }
];

const labelTerminos = `<span>
  Acepto los <a href="/terminos-y-condiciones" target="_blank">Términos y condiciones</a>
</span>
  `;

const labelTratamientos =`<span>
Acepto las <a href="/politicas-privacidad" target="_blank">Políticas de tratamiento de datos</a>
</span>
`;

class ServiceForm extends Component {
  constructor (props) {
    super(props);

    this.state = {
      listQuestions: []
    };
  }

  componentDidMount() {
    const radio = this.props.radioList.find((radio) => {
      return radio.value === this.props.initialValues.issue;
    });
    this.setState({listQuestions: radio.options});
  }

  render () {
    const {
      handleSubmit,
      onSubmit,
      onDelete,
      pristine,
      initialValues,
      reset,
      submitting,
      loadingForm,
      readOnly,
      radioList,
      termsValue,
      tratmentsValue
    } = this.props;

    return (
      <form onSubmit={handleSubmit(onSubmit.bind(this, this.state.listQuestions))} autoComplete="off">
        <Row>
          <Col xs={12}>
            <Field
              name="issue"
              id="service-tema"
              component={renderRadio}
              radioList={radioList}
              validate={[Validators.required]}
              onChange={event => {
                const radio = radioList.find((radio) => {
                  return radio.value === event.target.value;
                });
                this.setState({listQuestions: radio.options});
                reset();
              }}
            />
          </Col>
          {this.state.listQuestions.map((question, index) => {
            return (
              <Col xs={12} key={index}>
                <h6 className="mb-3 text-left"><span className="d-inline-block mr-3 circle-point">{question.id}</span>{question.name}</h6>
                <Field
                  name={`pregunta-${question.id}`}
                  id={`pregunta-${question.id}`}
                  formGroupClass="font-montserrat-light2"
                  indexRadio={index+1}
                  component={renderRadio}
                  radioList={optionsRadio}
                  validate={[Validators.required]}
                />
              </Col>
            );
          })}
          <Col xs={12} className="mb-4">
            <Field
              name="comment"
              id="servicio-comentario"
              icon="fa fa-comment-o"
              rows="2"
              label="Deja un Comentario"
              component={renderTextArea}
              validate={[Validators.required, Validators.minLength3, Validators.maxLength150]}
              readOnly={readOnly}
            />
          </Col>

          <Col xs={12}>
            <Row className="align-items-center">
              <Col xs={12} sm={12} md={8} className="text-left">
                <Field
                  name="terms"
                  id="terms"
                  formGroupClass="mb-0"
                  label={labelTerminos}
                  component={renderCheckBox}
                  validate={[Validators.required]}
                />
              </Col>
              <Col xs={12} sm={12} md={8} className="text-left">
                <Field
                  name="tratments"
                  id="tratments"
                  formGroupClass="mb-0"
                  label={labelTratamientos}
                  component={renderCheckBox}
                  validate={[Validators.required]}
                />
              </Col>
              <Col xs={12} sm={12} md={4} className="text-right">
                <button
                  type="submit"
                  className="btn btn-primary"
                  disabled={loadingForm || (!termsValue || !tratmentsValue)}
                >
                  Enviar
                  {loadingForm && <i className="fa fa-spinner fa-pulse ml-2 d-inline-block" />}
                </button>
              </Col>
            </Row>
          </Col>
        </Row>
      </form>
    );
  }
}
const selector = formValueSelector('ServiceForm');

const mapStateToProps = (state) =>{
  return {
    termsValue: selector(state, 'terms'),
    tratmentsValue: selector(state, 'tratments')
  };
};

export default connect(mapStateToProps)(reduxForm({ form: 'ServiceForm' })(ServiceForm));

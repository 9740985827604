// Dependencies
import React, { Component } from 'react';
import { Col, Row, Container } from 'reactstrap';

//Components
import DriedListContainer from './../../containers/Dried/DriedListContainer';

class Dried extends Component {
  render() {
    const {
      match: {
        params: {
          idLotterie = 0
        }
      }
    } = this.props;
    return (
      <Col xs={12} className="homeContainer">
        <Row>
          <Container>
            <section className="mt-5 mb-4 p-4">
              <h3 className="first-title text-center my-4 interna-resultados"><span className="color-white">Secos</span></h3>
              <DriedListContainer idLotterieURL={idLotterie}/>
            </section>
          </Container>
        </Row>
      </Col>
    );
  }
}

export default Dried;

import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Container, Row, Col, Alert } from 'reactstrap';
import { withRouter } from 'react-router-dom';
//Components
import CircularProgress from './../../components/Global/CircularProgress/CircularProgress';
import { loadLotteriesHome } from './../../actions/LotteriesActions';
import { setLotteryToPlay } from './../../actions/GameActions';
import Lotterie from './../../components/Lotteries/Lotterie';
import {
  pushEskEvent,
  pushFbqEvent,
  pushGtagEvent
} from '../../utils/metricManagementUtil';

import Loterias from '../../assets/images/Ver-Loterias.svg';
class LotteriesHomeContainer extends Component {
  constructor(props) {
    super(props);
    this.handleOnClickLotterie = this.handleOnClickLotterie.bind(this);
  }

  componentDidMount() {
    this.props.loadLotteriesHome({
      fecha: '11-04-2018',
      evento: null
    });
  }

  handleOnClickLotterie(idLotterie, sorteo) {
    pushGtagEvent('play_now', {lotteryId: idLotterie, draw: sorteo});
    const selectedLoti = this.props.lotteries.filter(lotterie => lotterie.ideLoteria === idLotterie && lotterie.sorteo === sorteo)[0];
    this.props.setLotteryToPlay(selectedLoti);
    this.props.history.push(`/juego/${idLotterie}/${sorteo}`);
  }

  render() {
    const { loading, error, lotteries } = this.props;
    const baseURL = process.env.REACT_APP_BASEURL;
    return (
      <Container>
        {/*---- Highlighted Loteries ----*/}
        <Row className="mt-2 mb-4 list-lotterie-home">
          <Col xs={12} className="text-center mb-4">
            <h3 className="first-title color-white">Próximas loterías a jugar</h3>
          </Col>
          {loading && <Col xs={12}><CircularProgress /></Col>}

          {!error && (lotteries && lotteries.length > 0 ? lotteries.slice(0, 4).map((item, i) => {
            return (
              <Col key={i} xs={12} sm={6} md={6} lg={6} className="mb-4">
                <Lotterie size="xs"
                  value={item.premioMayor ? item.premioMayor.vlrBrutoBillete : 0}
                  icon={`${baseURL}/assets/images/loterias/${item.abreviatura}.png`}
                  date={`${item.fecCierre} ${item.horaCierre}`}
                  name={item.desLoteria}
                  numeroFavorito={item.numeroFavorito}
                  sorteo={item.sorteo}
                  id={item.ideLoteria}
                  ticketValue={item.vlrBillete}
                  fractionValue={item.vlrFraccion}
                  fractions={item.numFracciones}
                  onLotterieClick={() => this.handleOnClickLotterie(item.ideLoteria, item.sorteo)}
                />
              </Col>
            );
          }) : !loading &&
          <Alert className="my-2 content-center" color="light">
            Lo sentimos, en este momento no tenemos loterías disponibles para jugar
          </Alert>
          )}
          {error && <Alert className="my-2 content-center" color="danger"> {error} </Alert>}
        </Row>
        {/*---- End Highlighted Loteries ----*/}

        {/*---- Other Loteries ----*/}
        {/* {lotteries && lotteries.length > 4 &&
          <Row className="mb-5 list-lotterie-home">
            <Col xs={12} className="text-center mb-4">
              <h3 className="first-title text-center color-white">Otras Loterías</h3>
            </Col>
            {lotteries.slice(4, 8).map((item, i) => {
              return (
                <Col key={i} xs={12} sm={6} md={6} lg={3} className="mb-4">
                  <Lotterie size="sm"
                    value={item.premioMayor ? item.premioMayor.vlrBrutoBillete : 0}
                    icon={`${baseURL}/assets/images/loterias/${item.abreviatura}.png`}
                    date={`${item.fecCierre} ${item.horaCierre}`}
                    name={item.desLoteria}
                    sorteo={item.sorteo}
                    id={item.ideLoteria}
                    ticketValue={item.vlrBillete}
                    fractionValue={item.vlrFraccion}
                    fractions={item.numFracciones}
                    onLotterieClick={() => this.handleOnClickLotterie(item.ideLoteria, item.sorteo)}
                  />
                </Col>
              );
            })}
          </Row>
        } */}
        {/*---- End Other Loteries ----*/}
        <div className="button-text-reverse-ver-loterias text-center">
          <a href='/loterias' className='button-text-reverse-jugar-loteri mr-3 text-center'>Ver todas las loterías &nbsp; &nbsp;<img className='log-cart' src={Loterias}/></a>
        </div>
      </Container>
    );
  }
}

const mapStateToProps = (state) => {
  const { loading, error, lotteries } = state.lotteries.lotteriesHome;
  return {
    loading,
    error,
    lotteries
  };
};

const mapDispatchToProps = dispatch => {
  return {
    loadLotteriesHome(values) {
      dispatch(loadLotteriesHome(values));
    },
    setLotteryToPlay(lottery) {
      dispatch(setLotteryToPlay(lottery));
    }
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(LotteriesHomeContainer));

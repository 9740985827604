import React, { Component, useEffect, useState } from 'react';

import PlanPremio from '../../assets/images/PlandePremios.svg';

import { loadPrizePlan } from './../../actions/PrizePlanActions';
import { connect } from 'react-redux';
import { Col } from 'reactstrap';
import CircularProgress from '../Global/CircularProgress/CircularProgress';

class Modal extends Component {
  constructor(props) {
    super(props);

    this.handleSearchPrizePlan = this.handleSearchPrizePlan.bind(this);

    this.state = {
      loadPrizePlan: false
    };
  }

  handleSearchPrizePlan = values => {
    this.props.loadPrizePlan({
      ideLoteria: values.loteria,
      fecha: values.fecha || null
    });
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.show && this.props.show != nextProps.show) {
      this.setState({ loadPrizePlan: true });
      this.props.loadPrizePlan({
        ideLoteria: this.props.idLotterieURL,
        fecha: null
      });
    }
  }

  render() {
    const { loading, prizePlan, show, onClose, title } = this.props;

    function formatNumberWithDecimals(number, decimals) {
      const roundedNumber = Number(number);
      return roundedNumber.toLocaleString(undefined, { minimumFractionDigits: 0, maximumFractionDigits: decimals });
    }

    return (
      <div className="modal" style={{ display: show ? 'flex' : 'none' }}>
        <div className="single-modal-prize-plan">
          <div className='single-close'>
            <p onClick={onClose} className='single-close'>X</p>
          </div>
          <div className='modal-secos'>
            <img src={PlanPremio} className='single-img' />
          </div>
          <h2 className='single-h2'>{title}</h2>
          <div className="table-prize-plan">
            {loading ? (
              <Col xs={12}>
                <CircularProgress />
              </Col>
            ) : (
              <table className="single-table">
                <thead>
                  <tr>
                    <th className='single-th' style={{ textAlign: 'left' }}>Descripción</th>
                    <th className='single-th' style={{ textAlign: 'right' }}>Bruto Billete</th>
                    <th className='single-th' style={{ textAlign: 'right' }}>Neto Billete</th>
                  </tr>
                </thead>
                <tbody>
                  {prizePlan && prizePlan.planPremios.map((row, index) => (
                    <tr key={row.ideLoteria}>
                      <td className={index % 2 === 0 ? 'single-cell' : 'single-cell-now'}>{row.desPlanPremio}</td>
                      <td className={index % 2 === 0 ? 'single-cell' : 'single-cell-now'} style={{ textAlign: 'right' }}>
                        ${formatNumberWithDecimals(row.vlrBrutoBillete, 2)}</td>
                      <td className={index % 2 === 0 ? 'single-cell' : 'single-cell-now'} style={{ textAlign: 'right' }}>
                        ${formatNumberWithDecimals(row.vlrNetoBillete, 2)}</td>
                    </tr>
                  ))}
                </tbody>
              </table>
            )}
          </div>
          <h1 className='single-footer pt-4'>*El valor neto es el valor del premio con todas las deducciones correspondientes a impuestos y otros gastos</h1>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  const { loading, error, prizePlan } = state.prizes;
  return {
    loading,
    error,
    prizePlan
  };
};

const mapDispatchToProps = dispatch => {
  return {
    loadPrizePlan(values) {
      dispatch(loadPrizePlan(values));
    }
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Modal);

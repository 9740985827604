import React from 'react';
import { FormGroup, FormText } from 'reactstrap';

const renderCheckBoxGame = ({input, label, id, disabled, meta: {touched, error, warning}, ...rest}) => {

  return (
    <div className={`position-relative check-fraction ${input.value ? 'animated bounce selected' : 'animated pulse'}`} >
      <input
        type="checkbox"
        className="custom-fraction"
        disabled={disabled}
        id={id}
        {...input}
        checked={input.value}
      />
      <label htmlFor={id}>
        <h4>Fracción</h4>
        <div className="rounded-fraction">
          {label}
        </div>
        {disabled &&<span>No disponible</span>}
        {!disabled &&
          <div>
            <span className="notChecked">Tomar <i className="d-inline-block ml-2 flaticon-hand-finger-pressing-a-circular-ring-button"/></span>
            <span className="checked">Devolver <i className="d-inline-block ml-2 flaticon-hand-finger-pressing-a-circular-ring-button"/></span>
          </div>
        }
      </label>
      {disabled && <div className="disabled" />}
      {touched &&
      ((error && <FormText className="text-left">{error}</FormText>) ||
      (warning && <FormText className="text-left">{warning}</FormText>))}
    </div>
  );
};


export default renderCheckBoxGame;

import React from 'react';
import { Col, Row, Container } from 'reactstrap';

const Eliminar = () => {
  return (
    <Col xs={12} className="homeContainer">
      <Row>
        <Container className="bg-primary">
          <section className="border mb-4 px-4 pb-4 back-white border-container eliminar-style">
            <h1>
                Cuenta eliminada
            </h1>
            <p>
                Lamentamos que nos dejes, pero siempre que desees volver te estaremos esperando con los brazos abiertos.
            </p>
            <a class="button-rounded button-rounded-blue d-inline-block align-middle w-85" href="/">
              <div class="center-right-button">
                <span>
                  <strong>
                    Regresar al inicio
                  </strong>
                </span>
              </div>
            </a>
          </section>
        </Container>
      </Row>
    </Col>

  );
};

export default Eliminar;

import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { withRouter } from 'react-router-dom';
import { toggleModalLogin, logoutUser } from './../../actions/UserActions';
import { formatMoney } from './../../helpers/formatMoney';

import Crear from './../../../src/assets/images/User_Create.svg';
import Inicio from './../../../src/assets/images/Inicio_Seccion.svg';

// Data
import { initEgoi } from '../../shared/egoi';

class InfoAccountHeader extends Component {
  componentWillReceiveProps(nextProps) {
    if (this.props.user && !nextProps.user) {
      this.props.history.push('/');
    }
  }

  render() {
    const { toggleModal, logoutUser, user } = this.props;

    function inicioSeccion(string) {
      //return string.charAt(0).toUpperCase() + string.slice(1).toLowerCase();
      if (string) {
        return string.charAt(0).toUpperCase() + string.slice(1).toLowerCase();
      }
      return ''; // o cualquier otro valor predeterminado que desees
    }

    if (!user) {
      return (
        <div className="media navbar-data-client">
          <span className="button-text-reverse mr-3 text-center" onClick={() => toggleModal('1')}>Mi cuenta <img className='log-cart' src={Crear} /></span>
        </div>
      );
    }
    if (user.email) {
      initEgoi(user.email);
    }
    return (
      <div className="media navbar-data-client popover-cutom-container">
        <div className="align-self-center mr-3">
          <img className='log-cart icon-user-account' src={Crear} />
        </div>
        <div className="d-md-none d-lg-inline-block">
          <h6 className='user-hello align-items-left'>Hola:</h6>
          <h5 className="mt-0 user-login">{inicioSeccion(user.nombres)} {inicioSeccion(user.primerApellido)} {user.prueba}</h5>
          <h5 className='user-login'>Saldo: {`$ ${formatMoney(user.saldo, 0, 3, '.', ',')}`}</h5>
        </div>
        <div className="popover-cutom">
          <div className="popover-cutom-content">
            <div className="button dropdown-menu popover-custom-menu font-montserrat-light">
              <Link className="dropdown-item" title="Ver Mi cuenta" to="/mi-cuenta/datos">
                <span>Mi cuenta</span>
              </Link>
              {user.comercial &&
                <Link className="dropdown-item" title="Ver Mi cuenta" to="/descargar-reportes">
                  <span>Descargar reportes</span>
                </Link>}
              <div className="dropdown-divider" />
              <button type="button" className="dropdown-item" title="Ver Mi cuenta" onClick={logoutUser}>
                <span>Salir</span>
              </button>
            </div>

          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    user: state.authentication.user
  };
};

const mapDispatchToProps = dispatch => {
  return {
    toggleModal(tab) {
      dispatch(toggleModalLogin(tab));
    },
    logoutUser() {
      dispatch(logoutUser());
    }
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(InfoAccountHeader));

import React from 'react';
import { Col, Row } from 'reactstrap';

const SubpageBlock = ({ subpage, activeIndex, toggleAccordion }) => {
  return (
    <div>
      <button
        className={`accordion ${activeIndex === subpage.id ? 'active' : ''}`}
        onClick={() => toggleAccordion(subpage.id)}
      >
        <h4 className="text-left text-bold collapse-title">{subpage.title}<i className={`fa ${activeIndex === 1 ? 'fa-chevron-up' : 'fa-chevron-down'}`}></i></h4>
      </button>
      <div
        className="panel"
        style={{
          display: activeIndex === subpage.id ? 'block' : 'none',
        }}
      >
        <Row className="align-center p-5">
          <Col lg={7}>
            <article dangerouslySetInnerHTML={{ __html: subpage.description }} />
          </Col>
          <Col lg={5} className="pr-0">
            <figure class="ratio-2-1">
              <img src={subpage.image} className="d-block w-100" />
            </figure>
          </Col>
        </Row>
      </div>
    </div>
  );
};

export default SubpageBlock;


// Dependencies
import React, { Component } from 'react';
import { Col, Row, Container, Alert } from 'reactstrap';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import NovedadesIcon from './../../../src/assets/images/novedades-icon.svg';

import { loadSingleNews } from './../../actions/NewsActions';

//components
import CircularProgress from './../../components/Global/CircularProgress/CircularProgress';

import defaultBroken from './../../assets/images/LotiColombia.svg';

class SingleNewsContainer extends Component {

  constructor(props) {
    super(props);
  }

  componentDidMount() {
    this.props.loadSingleNews(this.props.match.params.idNoticia);
  }

  render() {
    const { loading, error, news } = this.props;

    const handleError = (event) => {
      event.target.src = defaultBroken;
    }

    return (
      <Col xs={12} className="homeContainer">
        <Row>
          <Container className="bg-primary return-seccion">
            <section className="mt-5 mb-4 p-4 border-container">
              {loading && <CircularProgress />}
              {news &&
                <div>
                  <Row className="align-center pb-5">
                    <Col md={6}>
                      <img class="img-fluid mb-4 content-center d-block img-news" src={news.cover} alt="Cover" onError={handleError} />
                    </Col>
                    <Col md={6} className="interna-news">
                      <span class="dateNews">{news.createdAt.slice(0, 10)}</span>
                      <h3 class="first-title">{news.name}</h3>
                      <p><img src={NovedadesIcon} className='m-2' />{news.summary.slice(0, 100)}</p>
                    </Col>
                  </Row>
                  <div class="newsContent">
                    <section class="text-center text-size-normal text-container-news mb-4" dangerouslySetInnerHTML={{ __html: news.body }} />
                  </div>
                </div>
              }
              {error && <Alert className="my-2 content-center" color="danger"> {error} </Alert>}
            </section>
          </Container>
        </Row>
      </Col>
    );
  }
}

const mapStateToProps = (state) => {
  const { loading, error, news } = state.news.single;
  return {
    loading,
    error,
    news
  };
};

const mapDispatchToProps = dispatch => {
  return {
    loadSingleNews(idNews) {
      dispatch(loadSingleNews(idNews));
    }
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(SingleNewsContainer);

import React, { Component, useEffect, useState } from 'react';

import logoOki from '../../assets/images/logoOki.png';

import { connect } from 'react-redux';
import { Button, Col } from 'reactstrap';
import CircularProgress from '../Global/CircularProgress/CircularProgress';

import { loadRecargaOki } from '../../actions/RecargaOkiActions';
import { loadReclamaPremio } from '../../actions/ReclamarPremioActions';
import { decryptUserData, encryptUserData } from '../../services/security/informationCodingSecurity';

import RecargaOkiService from '../../services/recargaOkiService';

const ModalPagoPremios = ({
  loading,
  error,
  pagoPremio,
  loadPagoPremio,
  celularUsuario,
  nombreUsuario,
  saldoUsuario,
  colillaUsuario,
  numTransaccionUsuario,
  ideEstadoUsuario,
  ideUsuario,
  emailUsuario,
  apellidoUsuario,
  usuarioUser,
  usuarioUserConsulta,
  selectedPremioValor,
  selectedLoteria,
  show,
  handleOnSave,
  onClose,
  user,
  onCloseModal,
  loadReclamaPremio
}) => {
  const [showModalDatosPagoPremio, setShowModalDatosPagoPremio] = useState(false);

  const desLoteria = pagoPremio ? pagoPremio.desLoteria : null;
  const numero = pagoPremio && typeof pagoPremio.numero === 'string' ? pagoPremio.numero.replace(/\*/g, '') : null;
  const serieBillete = pagoPremio && typeof pagoPremio.serieBillete === 'string' ? pagoPremio.serieBillete.replace(/\*/g, '') : null;
  const sorteo = pagoPremio ? pagoPremio.sorteo : null;
  const vlrGanado = pagoPremio ? pagoPremio.vlrGanado : null;
  const desEstado = pagoPremio ? pagoPremio.desEstado : null;
  const fecSorteo = pagoPremio ? pagoPremio.fecSorteo : null;
  const colilla = pagoPremio ? pagoPremio.colilla : null;
  const numTransaccion = pagoPremio ? pagoPremio.numTransaccion : null;
  const LinkOkiEnv = process.env.REACT_APP_LINK_OKI;

  const [nuevoSaldo, setNuevoSaldo] = useState(null);

  const [mensajeActualizacion, setMensajeActualizacion] = useState('');
  const [vlrPremioActualizar, setVlrPremioActualizar] = useState(null);

  function generateUniqueCode() {
    const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789';
    const codeLength = 10;

    let uniqueCode = '';
    const timestamp = new Date().getTime().toString();

    for (let i = 0; i < codeLength; i++) {
      const randomIndex = Math.floor(Math.random() * characters.length);
      uniqueCode += characters.charAt(randomIndex);
    }
    return timestamp + uniqueCode;
  }

  const [ideUsuarioValidado, setIdeUsuarioValidado] = useState('');
  const [celularUsuarioValidado, setCelularUsuario] = useState('');

  useEffect(() => {
    if (user && user.userId && user.celular) {
      let obDecryptUserData = {
        userId: user.userId
      };
      obDecryptUserData = decryptUserData(obDecryptUserData);
      const { userId } = obDecryptUserData;
      setIdeUsuarioValidado(userId);
      setCelularUsuario(user.celular);
    }
  }, [user]);

  console.log('usariovalidado', ideUsuario);
  console.log('usariovalidado', ideUsuarioValidado);
  console.log('celularUsuarioValidado', celularUsuarioValidado);



  handleOnSave = () => {
    const values = {};
    const codigoAleatorio = generateUniqueCode();
    values.ideSolicitud = codigoAleatorio;
    let obDecryptUserData = {
      ideUsuario: ideUsuario
    };
    function formatearNumero(numero) {
      const numeroFormateado = numero.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
      return `$ ${numeroFormateado}`;
    }
    const fechaSorteo = new Date(fecSorteo);
    const dia = String(fechaSorteo.getDate()).padStart(2, '0');
    const mes = String(fechaSorteo.getMonth() + 1).padStart(2, '0');
    const año = String(fechaSorteo.getFullYear()).slice(2);
    const fechaFormateada = `${dia}/${mes}/${año}`;
    obDecryptUserData = decryptUserData(obDecryptUserData);
    values.ideUsuario = ideUsuarioValidado ? ideUsuarioValidado : obDecryptUserData.ideUsuario;
    console.log('values.ideUsuario', values.ideUsuario);
    values.loteria = desLoteria;
    values.sorteo = sorteo;
    values.numero = numero;
    values.serie = serieBillete;
    values.valor = formatearNumero(vlrGanado);
    values.medioPago = 'OKI';
    values.ideEstado = process.env.REACT_APP_DES_ESTADO;
    values.fecSorteo = fechaFormateada;
    console.log('values.ideUsuario');
    loadReclamaPremio(values);
  };

  const handleActualizarValores = () => {
    if (vlrPremioActualizar !== null) {
      setValues({
        ...values,
        valor: vlrPremioActualizar.toString()
      });
    }
  };

  const [saldoUsuarioUser, setSaldoUsuarioUser] = useState('');

  useEffect(() => {
    if (user && user.saldo) {
      setSaldoUsuarioUser(user.saldo);
    }
  }, [user]);

  useEffect(() => {
    if (colillaUsuario !== null && colillaUsuario !== undefined) {
      setValues((prevValues) => ({
        ...prevValues,
        colilla: colillaUsuario.toString()
      }));
    }
  }, [colillaUsuario]);

  const [values, setValues] = useState({
    usuarioUserConsulta: usuarioUserConsulta !== null && usuarioUserConsulta !== undefined ? usuarioUserConsulta.toString() : '',
    valor: nuevoSaldo !== null && nuevoSaldo !== undefined ? nuevoSaldo.toString() : '',
    ideEstado: process.env.REACT_APP_ESTADO,
    colilla: colillaUsuario !== null && colillaUsuario !== undefined ? colillaUsuario.toString() : ''
  });

  useEffect(() => {
    setValues((prevValues) => ({
      ...prevValues,
      ideEstado: process.env.REACT_APP_ESTADO
    }));
  }, []);

  useEffect(() => {
    if (pagoPremio && pagoPremio.vlrGanado !== null) {
      setVlrPremioActualizar(pagoPremio.vlrGanado.toString());
      const valorNumerico = isNaN(parseFloat(saldoUsuarioUser)) ? 0 : parseFloat(saldoUsuarioUser);

      setNuevoSaldo(valorNumerico + parseFloat(pagoPremio.vlrGanado));
      console.log('ytr: ', saldoUsuarioUser);
      console.log('ytr: ', pagoPremio.vlrGanado);
      console.log('ytr: ', saldoUsuarioUser + parseFloat(pagoPremio.vlrGanado));
    }
    console.log('pagoPremioR', pagoPremio);
  }, [pagoPremio]);

  function Mensaje({ message }) {
    return (
      <div className="mensaje">
        {message}
      </div>
    );
  }

  const [showMessage, setShowMessage] = useState(false);
  const [message, setMessage] = useState('');

  function enviarSMS(celularUsuarioValidado, fechaFormateada, vlrPremioFormateado, nombreUsuario, desLoteria) {
    const dataSMS = {
      celularUsuario: celularUsuarioValidado,
      fecSorteo: fechaFormateada,
      vlrGanado: vlrPremioFormateado,
      nombreUsuario: nombreUsuario,
      desLoteria: desLoteria
    };

    return RecargaOkiService.enviarSMS(dataSMS)
      .then(responseSMS => {
        console.log('SMS enviado con éxito:', responseSMS);
      })
      .catch(errorSMS => {
        console.error('Error al enviar SMS:', errorSMS);
      });
  };

  function enviarCorreo(emailUsuario, fechaFormateada, vlrPremioFormateado, LinkOkiEnv, desLoteria) {
    const dataCorreoCliente = {
      fecSorteo: fechaFormateada,
      vlrGanado: vlrPremioFormateado,
      desLoteria: desLoteria,
      LinkOkiEnv: LinkOkiEnv,
      emailUsuario: emailUsuario
    };

    RecargaOkiService.enviarCorreo(dataCorreoCliente)
      .then(responseCorreoBuscarS3 => {
        console.log('SMS enviado con éxito:', responseCorreoBuscarS3);
      })
      .catch(errorCorreoBuscarS3 => {
        console.error('Error al enviar SMS:', errorCorreoBuscarS3);
      });
  };

  function BuscarS3(numTransaccionUsuario) {
    const dataBuscarS3 = {
      numTransaccionUsuario: numTransaccionUsuario
    };

    RecargaOkiService.BuscarS3(dataBuscarS3)
      .then(responseBuscarS3 => {
        console.log('SMS enviado con éxito:', responseBuscarS3);
      })
      .catch(errorBuscarS3 => {
        console.error('Error al enviar SMS:', errorBuscarS3);
      });
  };

  function enviarCorreoReporte(usuarioUser, nombreUsuario, apellidoUsuario, celularUsuario, fechaFormateada, desLoteria, vlrPremioFormateado, numTransaccionUsuario) {
    const dataCorreoReporte = {
      usuarioUser: usuarioUser,
      nombreUsuario: nombreUsuario,
      apellidoUsuario: apellidoUsuario,
      celularUsuario: celularUsuario,
      fecSorteo: fechaFormateada,
      desLoteria: desLoteria,
      vlrPremioFormateado: vlrPremioFormateado,
      numTransaccionUsuario: numTransaccionUsuario
    };

    RecargaOkiService.enviarCorreoReporte(dataCorreoReporte)
      .then(responseCorreoReporte => {
        console.log('SMS enviado con éxito:', responseCorreoReporte);
      })
      .catch(errorCorreoReporte => {
        console.error('Error al enviar SMS:', errorCorreoReporte);
      });
  };

  const handleAceptarClick = () => {

    openModal();

    setMessage('Realizando recarga para su Cuenta Oki');
    setShowMessage(true);

    // setNuevoSaldo(nuevoSaldo);
    console.log('pagoPremioRetro', usuarioUserConsulta);
    console.log('pagoPremioRetro', nuevoSaldo);
    console.log('pagoPremioRetro', values.ideEstado);
    console.log('pagoPremioRetro', colilla);
    console.log('nuevoSaldo.toString()', nuevoSaldo.toString());

    RecargaOkiService.loadListRecargaOki({
      usuario: values.usuarioUserConsulta,
      valor: nuevoSaldo.toString(),
      ideEstado: values.ideEstado,
      colilla: values.colilla
    })
      .then((response) => {
        handleActualizarValores();
        console.log('Respuesta exitosa:', response);

        handleOnSave();

        const fecha = new Date(fecSorteo);
        const fechaFormateada = fecha.toLocaleDateString();

        const vlrPremioFormateado = vlrGanado.toLocaleString();

        enviarSMS(celularUsuarioValidado, fechaFormateada, vlrPremioFormateado, nombreUsuario, desLoteria);

        enviarCorreo(emailUsuario, fechaFormateada, vlrPremioFormateado, LinkOkiEnv, desLoteria);

        BuscarS3(numTransaccionUsuario);

        enviarCorreoReporte(usuarioUser, nombreUsuario, apellidoUsuario, celularUsuario, fechaFormateada, desLoteria, vlrPremioFormateado, numTransaccionUsuario);

        setMessage('Saldo cargado a su cuenta de OKI, por favor verificar');

        setTimeout(() => {
          onClose(); // Esta función debería cerrar el modal
          window.location.reload();
        }, 2000);
      })
      .catch((error) => {
        console.error('Error al realizar la recarga:', error);
        setMessage('Error al realizar la recarga');
        setShowMessage(true);
        onClose();
      });
  };

  const [showModal, setShowModal] = useState(false);

  const openModal = () => {
    setShowModal(true);
  };

  const closeModal = () => {
    setShowModal(false);
  };

  return (
    <div className="modal" style={{ display: show ? 'flex' : 'none' }}>
      {showModal && (
        <div className="modal">
          <div className="modal-dialog-centered">
            <CircularProgress message={message} />
          </div>
        </div>
      )}
      <div className="single-modal-content-pru">
        <div className='single-close'>
          <p onClick={onClose} className='single-close'>X</p>
        </div>
        <div className='modal-secos'>
          <img src={logoOki} className='single-img-pago mt20 mb20' />
        </div>
        <h2 className='h2-premio mb20'>Pagos Oki</h2>
        {loading ? (
          <Col xs={12}>
            <CircularProgress />
          </Col>
        ) : (
          <h6 className="text-center pb20 h6-description">Al reclamar tu premio por OKI el saldo total será cargado a tu cuenta inmediatamente,
            recuerda que también puedes reclamar tu premio en un punto físico</h6>
        )}
        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', marginTop: '20px' }}>
          {}
          <a onClick={onClose} className='button-text-reverse-pago-premio mr-3 text-center'>Cancelar</a>
          <Button color="primary" style={{ fontSize: '20px', fontWeight: 'bold' }} onClick={handleAceptarClick}>Aceptar</Button>
          {}
        </div>
        {}
      </div>
    </div>
  );
};

const mapStateToProps = (state) => {
  const { loading, error, recargaOki } = state.recarga;
  const { reclamo } = state.reclamo;
  const { user } = state.authentication;
  return {
    loading,
    error,
    recargaOki,
    reclamo,
    user
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    loadRecargaOki(values) {
      dispatch(loadRecargaOki(values));
    },
    loadReclamaPremio(values) {
      dispatch(loadReclamaPremio(values));
    }
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ModalPagoPremios);

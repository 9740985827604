import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Col, Row, Container, TabContent, TabPane } from 'reactstrap';

// Containers
import EditAccountContainer from './../../containers/Account/EditAccountContainer';
import ChangePasswordContainer from './../../containers/Account/ChangePasswordContainer';
import ShoppingHistoryContainer from './../../containers/Account/ShoppingHistoryContainer';
import { Tab, TabList, Tabs } from 'react-tabs';
import FormPaymentAward from '../../containers/Account/FormPaymentAwardContainer';
import { loadwebConfisysControlar } from '../../actions/WebConfisysControlarActions';

//components
import Breadcrumbs from './../../components/Global/Breadcrumbs/Breadcrumbs';

const enablePaymentTab = process.env.REACT_APP_ENABLE_PAYMENT_TAB;

class Account extends Component {
  constructor(props) {
    super(props);

    const currentPath = props.location.pathname;

    const pathSegments = currentPath.split('/');
    const lastSegment = pathSegments[pathSegments.length - 1];
    this.state = {
      activeTab: lastSegment
    };

    this.toggle = this.toggle.bind(this);
  }

  toggle(tab) {
    if (this.state.activeTab !== tab) {
      this.setState({ activeTab: tab });
      this.props.history.push(`/mi-cuenta/${tab}`);
    }
  }

  componentDidMount() {
    console.log('Efecto activado');
    this.props.loadwebConfisysControlar({ nombre: this.state.nombre });
  }

  componentDidUpdate(prevProps) {
    if (prevProps.webConfisysControlar !== this.props.webConfisysControlar) {
      if (this.props.webConfisysControlar && this.props.webConfisysControlar.length > 0) {
        const valorStr = this.props.webConfisysControlar[0].valorStr;
        this.setState({ valorStr });
        console.log('Valor de valorStr:', valorStr);
      }
    }
  }

  render() {
    const { activeTab, valorStr } = this.state;
    const currentPath = this.props.location.pathname;
    const pathSegments = currentPath.split('/');
    const lastSegment = pathSegments[pathSegments.length - 1];

    let defaultIndex;
    switch (lastSegment) {
      case 'datos':
        defaultIndex = 0;
        break;
      case 'cambiar-contrasena':
        defaultIndex = 1;
        break;
      case 'historial':
        defaultIndex = 2;
        break;
      case 'pago-premios':
        defaultIndex = 3;
        break;
      default:
        defaultIndex = 0;
        break;
    }

    return (
      <Col xs={12} className="homeContainer">
        <Row>
          <Container className="bg-primary">
            <Breadcrumbs />
            <section className="mb-4 px-4 pb-4">
              <div className="general-tabs tabs-page NavLinkNoPadding tab-container-account">
                <Tabs tabs fill defaultIndex={defaultIndex}>
                  <TabList className="react-tabs__tab-list tab-container">
                    <Tab
                      className="button-rounded button-rounded-puntos px-20 py5"
                      onClick={() => this.toggle('datos')}
                    >
                      Datos e Información
                    </Tab>
                    <Tab
                      className="button-rounded button-rounded-puntos px-20 py5"
                      onClick={() => this.toggle('cambiar-contrasena')}
                    >
                      Cambiar Contraseña
                    </Tab>
                    <Tab
                      className="button-rounded button-rounded-puntos px-20 py5"
                      onClick={() => this.toggle('historial')}
                    >
                      Historial de Compras
                    </Tab>
                    <Tab
                      className="button-rounded button-rounded-puntos px-20 py5"
                      onClick={() => this.toggle('pago-premios')}
                    >
                      Pago de premios
                    </Tab>

                  </TabList>
                </Tabs>
                <TabContent activeTab={activeTab}>
                  <TabPane tabId="datos" className="p-5 text-center">
                    {activeTab === 'datos' && <EditAccountContainer />}
                  </TabPane>

                  <TabPane tabId="cambiar-contrasena" className="p-5 text-center">
                    {activeTab === 'cambiar-contrasena' && <ChangePasswordContainer />}
                  </TabPane>

                  <TabPane tabId="historial" className=" text-center">
                    {activeTab === 'historial' && <ShoppingHistoryContainer />}
                  </TabPane>

                  <TabPane tabId="pago-premios" className="p-2 text-center">
                    {activeTab === 'pago-premios' && <FormPaymentAward />}
                  </TabPane>

                </TabContent>
              </div>
            </section>
          </Container>
        </Row>
      </Col>
    );
  }
}

const mapStateToProps = (state) => {
  const { webConfisysControlar } = state.confisysControlar;
  return {
    webConfisysControlar
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    loadwebConfisysControlar(values) {
      dispatch(loadwebConfisysControlar(values));
    }
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Account);

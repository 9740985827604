import React, { Component } from 'react';
import moment from 'moment';
import 'moment/locale/es';
import { Row, Col, Card, CardTitle, CardText } from 'reactstrap';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';

// Components
import CountDown from './CountDown';
import { formatMoney } from './../../helpers/formatMoney';
import Modal from '../Modal/ModalPlanPremios';
import ModalDetalle from '../Modal/ModalDetalleLoteria';

// Assets
import Reloj from '../../assets/images/Reloj.svg';
import NumberFav from '../../assets/images/I-Number-fav.svg';
import DetallesValor from '../../assets/images/DetallesValorFraccion.svg';
import DetallesFracciones from '../../assets/images/DetallesFracciones.svg';
import DetallesTotal from '../../assets/images/DetallesTotal.svg';

class Lotterie extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showModal: false,
      showModalDetalle: false
    };

    // Binds
    this.openModal = this.openModal.bind(this);
    this.closeModal = this.closeModal.bind(this);
  }

  openModal() {
    this.setState({ showModal: true });
  }

  closeModal() {
    this.setState({ showModal: false });
  }

  textTime() {
    const { date } = this.props;
    const _date = date.replace(' ', 'T');
    const countDownDate = new Date(_date).getTime();
    const now = new Date().getTime();
    const diff = countDownDate - now;
    const days = (diff / (1000 * 60 * 60 * 24));
    const day = moment(_date).format('DD MMMM');
    const hour = moment(_date).format('h:mm a');

    if (days > 1) {
      return `${day} / ${hour}`;
    } else {
      return <CountDown date={_date} />;
    }
  }

  render() {
    const {
      size,
      value,
      icon,
      date,
      name,
      numeroFavorito,
      horaCierre,
      sorteo,
      id,
      onLotterieClick,
      ticketValue,
      fractionValue,
      fractions
    } = this.props;
    const _date = date.replace(' ', 'T');
    const day = moment(_date).format('DD MMMM');
    const hour = moment(_date).format('h:mm a');

    const numberFavortio = numeroFavorito ? numeroFavorito.toString().split('') : [0, 0, 0, 0];
    const valor = value / 1000000;

    if (size === 'lg') {
      return (
        <Card body className="text-center single-lotterie single-loteri">
          <Row className="m-0 align-items-center single-lotterie-lg large_lotterie">
            <Col md="3" lg="4" className="p-0">
              <div className="mb-3 image-lotterie-principal mx-auto">
                <img className="d-block" src={icon} alt={`Icono lotería ${name}`} />
              </div>
            </Col>
            <Col md="9" lg="8" className="pr-0">
              <Row className="align-items-center">
                <Col xs={12} sm={12} md={5} lg={6} xl={7} className="p-0 large_lotterie_text">
                  <CardTitle tag="h5"><strong>Premio mayor:</strong> <br /> <span>{`$ ${formatMoney(value, 0, 3, '.', ',')}`}</span> </CardTitle>
                  <CardText>{this.textTime()}</CardText>
                </Col>
                <Col xs={12} sm={12} md={7} lg={6} xl={5}>
                  <Link className="button-rounded button-rounded-white" to={`/plan-premios/${id}`}>
                    <div className="button-rounded button-rounded-gradient-resultados px-30 py5">
                      <span>Plan de premios</span>
                    </div>
                  </Link>
                  <br />
                  <button
                    type="button"
                    className="button-rounded button-rounded-gradient-jugar-resultado px-30 py5"
                    onClick={onLotterieClick}
                  >
                    <div className="d-flex justify-content-between align-items-center">
                      <span>Jugar ahora</span>
                    </div>
                  </button>
                </Col>
              </Row>
            </Col>
          </Row>
        </Card>
      );
    }

    if (size === 'md') {
      return (
        <Card body className="text-center single-lotterie single-lotterie-flex single-loteri">
          <Row className="m-0 align-items-center single-lotterie-md large_lotterie">
            <Col className="p-0">
              <Row className="align-items-center">
                <Col md={4} className="pt-2">
                  <div className="image-lotterie-jugar mx-auto">
                    <img className="d-block" src={icon} alt={`Icono lotería ${name}`} />
                  </div>
                </Col>
                <Col md={8} className="large_lotterie_text">
                  <CardTitle className="text-center" tag="h5"><strong className="single">Premio mayor:</strong> <br /> <span>{`$ ${formatMoney(value, 0, 3, '.', ',')}`}</span> </CardTitle>
                  <CardText className="text-center">{this.textTime()}</CardText>
                  <button
                    type="button"
                    className="button-rounded button-rounded-gradient-jugar-resultado px-30 py5"
                    onClick={onLotterieClick}
                  >
                    <div className="d-flex justify-content-between align-items-center">
                      <span>Jugar ahora</span>
                    </div>
                  </button>
                </Col>
              </Row>
            </Col>
          </Row>
        </Card>
      );
    }

    return (
      <Card body className=" single-lotterie single-lotterie-flex single-loteri">
        <Row className="m-0 pb-2 align-items-center large_lotterie">
          <Col xs="6" sm="6" md="6" className='p-0'>
            <Modal
              title="Plan de premios"
              show={this.state.showModal}
              onClose={this.closeModal}
              idLotterieURL={id}
            />
            <div className="mb-3 mt-3 image-lotterie mx-auto">
              <img className="d-block" src={icon} alt={`Icono lotería ${name}`} />
            </div>
            <h4 className='single-name text-center'>{name}</h4>
            <Row className='align-center detail-text mt-2'>
              <Col xl={12} lg={12} md={12} sm={12} xs={12} className='mb-1 d-flex align-items-center' >
                <img src={DetallesFracciones} className='single-img-Detalle d-none d-sm-block' />
                <span className='single-number-fav-h6' style={{ color: 'white', paddingLeft: '5px' }}>Fracciones por billete
                  <span className='single-span-Detalle-valor bolder no-mrn'>{' '}{fractions}</span>
                </span>
              </Col>
              <Col xl={12} lg={12} md={12} sm={12} xs={12} className='mb-1 d-flex align-items-center'>
                <img src={DetallesValor} className='single-img-Detalle d-none d-sm-block' />
                <span className='single-number-fav-h6' style={{ color: 'white', paddingLeft: '5px' }}>Valor Fracción
                  <span className='single-span-Detalle-valor bolder no-mrn'>{' '}{`$ ${formatMoney(fractionValue, 0, 3, '.', ',')}`}</span>
                </span>
              </Col>
              <Col xl={12} lg={12} md={12} sm={12} xs={12} className='mb-1 d-flex align-items-cente'>
                <img src={DetallesTotal} className='single-img-Detalle d-none d-sm-block' />
                <span className='single-number-fav-h6' style={{ color: 'white', paddingLeft: '5px' }}>Valor del Billete
                  <span className='single-span-Detalle-valor bolder no-mrn'>{' '}{`$ ${formatMoney(ticketValue, 0, 3, '.', ',')}`}</span>
                </span>
              </Col>
            </Row>
          </Col>
          <Col xs="6" sm="6" md="6" className="p-0 large_lotterie_text position-pre text-center">
            <strong className='single-pre'>Premio Mayor</strong> <br />
            <span className='single-money-s'>$</span>
            <span className='single-money'>{`${formatMoney(valor, 0, 3, '.', ',')}`}</span>
            <h6 className='single-millones'>⭑⭑ MILLONES ⭑⭑</h6>
            <a
              className="btn single-button button-rounded button-rounded-gradient-lotteries sm mt-1"
              onClick={onLotterieClick}
            >
              <span className='single-span'>Jugar</span>
            </a>
            <hr className='single-hr' />
            <div className='single-number-fav'>
              <h6 className='single-number-fav-h6'><img src={NumberFav} />&nbsp; Número favorito</h6>
            </div>
            <div className='single-number-fav'>
              {numberFavortio.map((number, i) => (
                <span key={i} className="rounded-number-new">{number}</span>
              ))}
            </div>
            <hr className='single-hr' />
            <div className='single-actions-modal'>
              <a className='single-actions pl-3' onClick={this.openModal}>Plan de premios &nbsp;</a>
            </div>
          </Col>
        </Row>
        <Row>
          <Col xs="12" sm="12" md="12" className="p-0 large_lotterie_text position-pre">
            <div className='single-ciere mx-auto' style={{ display: 'flex', alignItems: 'center', margin: 'auto', justifyContent: 'center' }}>
              <h6 className='single-h6' style={{ margin: 0, width: '150px' }}>
                <img className='single-reloj' src={Reloj} style={{ width: '30px' }} />
                Las ventas cierran a las:
                </h6>
              <CardText className='single-conteo'>{this.textTime()}</CardText>
            </div>
          </Col>
        </Row>
      </Card>
    );
  }
}


export default Lotterie;

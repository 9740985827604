import ReactGA from 'react-ga';

const useGaTracker = () => {
  ReactGA.initialize('UA-210623216-1', {
    debug:false,
    titleCase: false,
    testMode : false,
    useExistingGa: true
  });
  ReactGA.pageview(window.location.pathname + window.location.search);
};

const ModalsGaTracker = (url) => {
  ReactGA.modalview(url);
};

const EventsGaTracker = (category, action) => {
  ReactGA.event({
    category: category,
    action: action
  });
};

export { useGaTracker, ModalsGaTracker, EventsGaTracker };

// Components
import Home from './../components/Home/Home';
import Lotteries from './../components/Lotteries/Lotteries';
import LotterieWinnerContainer from './../containers/Lotteries/LotterieWinnerContainer';
import PrizePlan from './../components/PrizePlan/PrizePlan';
import Results from './../components/Results/Results';
import Dried from './../components/Dried/Dried';
import Reloads from './../components/Reloads/Reloads';
import ReloadsPhone from './../components/Reloads/ReloadsPhone/ReloadsPhone';
import ReloadsPackages from './../containers/Reloads/ReloadsPackagesContainer';
import GameContainer from './../containers/Game/GameContainer';
import AboutContainer from './../containers/About/AboutContainer';
import ProductsPageContainer from './../containers/Products/ProductsPageContainer';
import Contact from './../components/Contact/Contact';
import Account from './../components/Account/Account';
import DownloadReportsContainer from './../containers/Account/DownloadReportsContainer';
import ResetPasswordContainer from './../containers/Account/ResetPasswordContainer';
import CartContainer from './../containers/Cart/CartContainer';
import OrderConfirmContainer from './../containers/Cart/OrderConfirmContainer';
import ConfirmContainer from './../containers/Account/ConfirmAccountContainer';
import Page404 from './../components/Page404';
import SingleNewsContainer from './../containers/News/SingleNewsContainer';
import PrivacyPolicies from './../components/Global/PrivacyPolicies';
import Eliminar from './../components/Global/Eliminar/Eliminar';
import TermsAndConditions from './../components/Global/TermsAndConditions';
import GanaPointsContainer from './../containers/GanaPoints/GanaPointsContainer';
import MessageComponent from '../components/Message/MessageComponent';
import DeleteAccountContainer from './../containers/Account/DeleteAccountContainer';
import React from 'react';
import { Redirect } from 'react-router-dom';


// Containers

const routes = [
  {
    path: '/',
    component: Home,
    exact: true
  },
  {
    path: '/loterias',
    component: Lotteries,
    exact: true
  },
  {
    path: '/message',
    component: MessageComponent,
    exact: true
  },
  {
    path: '/eres-ganador',
    component: LotterieWinnerContainer,
    exact: true
  },
  {
    path: '/plan-premios/:idLotterie',
    component: PrizePlan,
    exact: true
  },
  {
    path: '/secos/:idLotterie',
    component: Dried,
    exact: true
  },
  {
    path: '/noticias/:idNoticia',
    component: SingleNewsContainer,
    exact: true
  },
  {
    path: '/resultados/:date',
    component: Results,
    exact: false
  },
  {
    path: '/resultados',
    component: Results,
    exact: false
  },
  /*{
    path: '/recargas',
    component: Reloads,
    exact: true
  },
  {
    path: '/recargas/celular',
    component: ReloadsPhone,
    exact: true
  },
  {
    path: '/recargas/paquetes',
    component: ReloadsPackages,
    exact: true
  },*/
  {
    path: '/juego/:idLotterie/:idSorteo',
    component: GameContainer,
    exact: true
  },
  {
    path: '/nosotros/:tab',
    component: AboutContainer,
    exact: true
  },
  {
    path: '/nosotros',
    exact: true,
    render: () => <Redirect to="/nosotros/nuestra-empresa" />
  },
  {
    path: '/productos',
    component: ProductsPageContainer,
    exact: true
  },
  {
    path: '/puntos-gana',
    component: GanaPointsContainer,
    exact: true
  },
  {
    path: '/contacto/:tab',
    component: Contact,
    exact: true
  },
  {
    path: '/contacto',
    exact: true,
    render: () => <Redirect to="/contacto/pqrs" />
  },
  {
    path: '/mi-cuenta/:tab',
    component: Account,
    exact: true,
    private: true
  },
  {
    path: '/mi-cuenta',
    exact: true,
    render: () => <Redirect to="/mi-cuenta/datos" />
  },
  {
    path: '/confirmar-cuenta/:token',
    component: ConfirmContainer,
    exact: true
  },
  {
    path: '/descargar-reportes',
    component: DownloadReportsContainer,
    exact: true,
    private: true
  },
  {
    path: '/recuperar-contrasena/:token',
    component: ResetPasswordContainer,
    exact: true,
    private: false
  },
  // {
  //   path: '/confirmar',
  //   component: ResetPasswordContainer,
  //   exact: true
  // },
  {
    path: '/eliminar',
    component: Eliminar,
    exact: true
  },
  {
    path: '/eliminar-cuenta',
    component: DeleteAccountContainer,
    exact: true,
    private: true
  },
  {
    path: '/carrito',
    component: CartContainer,
    exact: true
  },
  {
    path: '/confirmacion/:referencia',
    component: OrderConfirmContainer,
    exact: true,
    private: false
  },
  {
    path: '/politicas-privacidad',
    component: PrivacyPolicies,
    exact: true
  },
  {
    path: '/terminos-y-condiciones',
    component: TermsAndConditions,
    exact: true
  },
  {
    component: Page404
  }
];

export default routes;

import React, { useEffect, useState, useRef } from 'react';
import { connect } from 'react-redux';
import { Alert, Col, Container, Row } from 'reactstrap';
import Slider from 'react-slick';

// Components
import CircularProgress from '../../components/Global/CircularProgress/CircularProgress';
import { loadHomePayPrize } from '../../actions/HomePayPrizeActions';

import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import Homepayprize from '../../components/Payment/HomePaymentAward';

import { Tab, TabList, Tabs } from 'react-tabs';
import PagoPremioListContainer from '../PagoPremio/PagoPremioContainer';

// const CustomPrevArrow = ({ onClick }) => (
//   <button className="custom-carousel-arrow custom-carousel-prev-arrow" onClick={onClick}/>
// );

// const CustomNextArrow = ({ onClick }) => (
//   <button className="custom-carousel-arrow custom-carousel-next-arrow" onClick={onClick}/>
// );

const FormPaymentAwardHome = ({ loading, error, homepayprize, loadHomePayPrize }) => {
  useEffect(() => {
    loadHomePayPrize();
  }, [loadHomePayPrize]);

  console.log('loadHomePayPrize', homepayprize);

  const sortedpay = homepayprize && [...homepayprize].sort((a, b) => b.ideSeccion - a.ideSeccion);

  const sliderRef = useRef(null);
  const [currentCarousel, setCurrentCarousel] = useState(0);

  const prevCarousel = () => {
    sliderRef.current.slickPrev();
  };

  const nextCarousel = () => {
    sliderRef.current.slickNext();
  };

  const settings = {
    infinite: true,
    arrows: false,
    // prevArrow: <CustomPrevArrow onClick={prevCarousel} />,
    // nextArrow: <CustomNextArrow onClick={nextCarousel} />,
    initialSlide: currentCarousel,
    beforeChange: (_, nextSlide) => setCurrentCarousel(nextSlide),
    // className: 'custom-carousel' + slidesToShows,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 5000,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1
        }
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1
        }
      }
    ]
  };

  const renderCarousel = (newsGroup, index) => {
    const shouldRenderSlider = newsGroup.length > 1;

    if (shouldRenderSlider) {
      return (
        <Slider ref={sliderRef} key={index} {...settings}>
          {newsGroup.map((newsItem, i) => (
            <div className="custom-carousel-item" key={i}>
              <div>
                <Homepayprize {...newsItem} />
              </div>
            </div>
          ))}
        </Slider>
      );
    } else {
      return (
        <div className="custom-carousel-item" style={{ display: 'flex', alignItems: 'center', justifyContent:'center' }}>
          {newsGroup.map((newsItem, i) => (
            <div key={i} className="news-item-wrapper-pay-pago">
              <Homepayprize {...newsItem} />
            </div>
          ))}
        </div>
      );
    }
  };

  const renderCarousels = () => {
    if (!sortedpay) {
      return null;
    }

    const groups = [sortedpay];

    return groups.map((group, index) => (
      <Col sm={12} key={index}>
        {renderCarousel(group, index)}
      </Col>
    ));
  };

  return (
    <div>
      {loading ? (
        <Col xs={12}><CircularProgress /></Col>
      ) : (
        <Container>
          <Row className="justify-content-center">
            {renderCarousels()}
          </Row>
        </Container>
      )}
    </div>
  );
};

const mapStateToProps = (state) => {
  const { loading, error, homepayprize } = state.homepayprize.homepayPrize;
  return {
    loading,
    error,
    homepayprize,
    user: state.authentication.user
  };
};

const mapDispatchToProps = dispatch => {
  return {
    loadHomePayPrize(values) {
      dispatch(loadHomePayPrize(values));
    },
    toggleModal(tab) {
      dispatch(toggleModalLogin(tab));
    },
    logoutUser() {
      dispatch(logoutUser());
    }
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(FormPaymentAwardHome);

import React, { useState, useEffect } from 'react';
import { Box, Alert } from '@mui/material';
import { useCart } from './CartContext'; // Asegúrate de tener este hook correctamente implementado
import { priceFormatter, formatMoney } from './../../helpers/formatMoney'; // Si tienes una función para formatear el dinero
import Response$ from '../../events/emitter';
import moment from 'moment'; // Asegúrate de que moment.js esté instalado si necesitas usarlo

const baseURL = process.env.REACT_APP_BASEURL;

const TableCart = ({ products, removeFromCart }) => {
  const [rows, setRows] = useState([]);
  const {
    porcentajeCoupon,
    setPorcentajeCoupon,
    loteries,
    setLoteries,
    numeroFraccionesMinimas,
    setNumeroFraccionesMinimas,
    numeroFraccionesMaximas,
    setNumeroFraccionesMaximas,
  } = useCart();

  const PriceCell = ({ value }) => {
    if (value === undefined || value === null || isNaN(value)) {
      return <span>$ 0</span>;
    }
    return <span>$ {formatMoney(value, 0, 3, '.', ',')}</span>;
  };

  useEffect(() => {
    const handleEvent = (mensaje) => {
      setPorcentajeCoupon(mensaje.message.porcentaje_descuento || null);
      setLoteries(mensaje.loteries || []);
      setNumeroFraccionesMinimas(mensaje.message.numeroFraccionesMinimas || null);
      setNumeroFraccionesMaximas(mensaje.message.numeroFraccionesMaximas || null);
    };

    Response$.once('myEvent', handleEvent);

    return () => {
      // Response$.off('myEvent', handleEvent); // Si necesitas remover el listener
    };
  }, [setPorcentajeCoupon, setLoteries]);

  useEffect(() => {
    if (porcentajeCoupon > 0 && loteries && products) { // Aquí agregamos la validación
      const discountMap = {};
      loteries.forEach((obj) => {
        discountMap[obj.idLoteria] = porcentajeCoupon;
      });

      const updatedProducts = products.map((product) => {
        const { ideLoteria, fracciones, discountApplied = false, total = 0 } = product;

        if (!discountApplied && fracciones >= numeroFraccionesMinimas && fracciones <= numeroFraccionesMaximas) {
          const discountPercentage = discountMap[ideLoteria] || 0;
          const valordescuento = discountPercentage ? total - (total * discountPercentage) / 100 : total;

          return {
            ...product,
            valordescuento,
            descuento: discountPercentage > 0 ? `${discountPercentage}%` : '0%',
            discountApplied: true,
          };
        } else if (!discountApplied) {
          return {
            ...product,
            valordescuento: total,
            descuento: '0%',
            discountApplied: true,
          };
        }
        return product;
      });

      setRows(updatedProducts);
    } else {
      setRows(products); // Si no hay descuento, muestra los productos originales sin cambios
    }
  }, [porcentajeCoupon, loteries, products, numeroFraccionesMinimas, numeroFraccionesMaximas]);

  return (
    <section className="table-custom-data-cart table-cart table-cart-juegos">
      {!rows.length ? (
        <Alert className="my-2 content-center" severity="warning">
          No ha jugado ningún sorteo
        </Alert>
      ) : (
        <div className='table-full-width'>
          <div className='lotti-table'>
            <div className='lotti-row header blue'>
              <div className='cell'>Lotería</div>
              <div className='cell'>Número</div>
              <div className='cell'>Serie</div>
              <div className='cell'>Fracciones</div>
              <div className='cell'>Valor</div>
              {porcentajeCoupon > 0 && (
                <>
                  <div className='cell'>Descuento</div> {/* Nueva columna para el descuento */}
                  <div className='cell'>Valor con Descuento</div> {/* Nueva columna para el valor con descuento */}
                </>
              )}
              <div className='cell'>Acciones</div>
            </div>

            {rows.map((row) => (
              <div className='lotti-row' key={row.id}>
                <div className='cell' data-title='Lotería'>
                  <img
                    src={`${baseURL}/assets/images/loterias/${row.abreviatura}.png`}
                    className="image-lotterie"
                    alt="Lotería"
                  />
                </div>
                <div className='cell' data-title='Número'>{row.numero}</div>
                <div className='cell' data-title='Serie'>{row.serie}</div>
                <div className='cell' data-title='Fracciones'>{row.fracciones}</div>
                <div className='cell' data-title='Valor'><PriceCell value={row.total} /></div> {/* Valor original sin descuento */}
                {porcentajeCoupon > 0 && (
                  <>
                    <div className='cell' data-title='Descuento'>{row.descuento}</div> {/* Muestra el descuento */}
                    <div className='cell' data-title='Valor con Descuento'><PriceCell value={row.valordescuento} /></div> {/* Muestra el valor con descuento */}
                  </>
                )}
                <div className='cell' data-title='Acciones'>
                  <button
                    type="button"
                    className="icon-button"
                    title="Eliminar del Carrito de Compras"
                    onClick={() => removeFromCart({ id: row.id }, 'lotteries')}
                  >
                    {row.deleting ? (
                      <i className="fa fa-spinner fa-pulse" />
                    ) : (
                      <i className="flaticon-can" />
                    )}
                  </button>
                </div>
              </div>
            ))}
          </div>
        </div>
      )}
    </section>
  );
};

export default TableCart;
